import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const AddOptionModal = ({ addOptionModal, addOptionToggle, surveyActions, surveyQuestion }) => {

  const [option, setOption] = useState("");
  const dispatch = useDispatch();

  const handleAddOption = () => {
    dispatch(surveyActions.adminAddSurveyOption(surveyQuestion?._id, { options: [...surveyQuestion?.options, option], counts: Array(surveyQuestion?.options?.length + 1).fill(0) }, addOptionToggle, setOption));
  };

  return (
    <React.Fragment>
      <Modal isOpen={addOptionModal} toggle={addOptionToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Add Survey Option</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Row>
            <Col>
              <FormGroup>
                <Input type="text" placeholder="Option" value={option} onChange={e => setOption(e.target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            addOptionToggle();
            setOption("");
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleAddOption()
          }}>Add</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default AddOptionModal;