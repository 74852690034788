import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as dropdownActions from "../../redux/dropdown/dropdown.actions";
import * as surveyActions from "../../redux/survey/survey.actions";

const FilterBody = ({ state, userActions, search, usersFilter, setUsersFilter, setIndex }) => {

  const dispatch = useDispatch();

  const countriesUserFilter = useSelector(state => state.surveyInfo.countriesUserFilter);
  const statesUserFilter = useSelector(state => state.surveyInfo.statesUserFilter);
  const citiesUserFilter = useSelector(state => state.surveyInfo.citiesUserFilter);
  const regionsUserFilter = useSelector(state => state.surveyInfo.regionsUserFilter);
  const provinciasUserFilter = useSelector(state => state.surveyInfo.provinciasUserFilter);
  const comunesUserFilter = useSelector(state => state.surveyInfo.comunesUserFilter);
  const civilStateList = useSelector(state => state.dropdownInfo.civilStateType);
  const educationList = useSelector(state => state.dropdownInfo.educationType);

  useEffect(() => {
    dispatch(surveyActions.getUserFilterCountryList());
    dispatch(surveyActions.getUserFilterRegionList());
  }, []);

  useEffect(() => {
    if (usersFilter?.country) {
      dispatch(surveyActions.getUserFilterStateList(usersFilter?.country));
    }
    if (usersFilter?.region) {
      dispatch(surveyActions.getUserFilterCityList(usersFilter?.region));
    }
    if (usersFilter?.country === "107") {
      if (usersFilter?.italy_region) {
        dispatch(surveyActions.getUserFilterProvinciaList(usersFilter?.italy_region));
      }
      if (usersFilter?.provincia) {
        dispatch(surveyActions.getUserFilterComuneList(usersFilter?.provincia));
      }
    }
  }, [usersFilter]);

  const handleClearAllFilter = () => {
    setUsersFilter({
      minAge: 1,
      maxAge: 100,
      attendedSurvey: null,
      createdSurvey: null,
      createdGroup: null,
      gender: null,
      country: "",
      italy_region: "",
      provincia: "",
      comune: "",
      region: "",
      city: "",
      civil: "",
      education: ""
    });
    dispatch(userActions.getUserListDetails(search, state?.id || "", state?.users || [], {
      minAge: 1,
      maxAge: 100,
      attendedSurvey: null,
      createdSurvey: null,
      createdGroup: null,
      gender: null,
      country: "",
      italy_region: "",
      provincia: "",
      comune: "",
      region: "",
      city: "",
      civil: "",
      education: ""
    }));
    setIndex(0);
  };

  const handleApplyFilter = () => {
    if (usersFilter.minAge < 0 || usersFilter.maxAge < 0 || usersFilter.minAge === null || usersFilter.maxAge === null) {
      toast("Enter valid age");
      return;
    }
    dispatch(userActions.getUserListDetails(search, state?.id || "", state?.users || [], usersFilter));
    setIndex(0);
  };

  return (
    <React.Fragment>
      <FormGroup>
        <Label>Min age</Label>
        <Input type="number" value={usersFilter.minAge || null} onChange={e => setUsersFilter({ ...usersFilter, minAge: Number(e.target.value) })} />
      </FormGroup>
      <FormGroup>
        <Label>Max age</Label>
        <Input type="number" value={usersFilter.maxAge || null} onChange={e => setUsersFilter({ ...usersFilter, maxAge: Number(e.target.value) })} />
      </FormGroup>
      <hr />

      <p>Gender</p>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="genderFilter" checked={usersFilter.gender === "male"} onChange={e => setUsersFilter({ ...usersFilter, gender: "male" })} />{' '}
          Male
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="genderFilter" checked={usersFilter.gender === "female"} onChange={e => setUsersFilter({ ...usersFilter, gender: "female" })} />{' '}
          Female
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="genderFilter" checked={usersFilter.gender === null} onChange={e => setUsersFilter({ ...usersFilter, gender: null })} />{' '}
          All
        </Label>
      </FormGroup>
      <hr />

      <p>Place</p>
      <FormGroup>
        <Label>Country</Label>
        <Input type="select" value={usersFilter.country} onChange={e => {
          if (e.target.value === "") {
            setUsersFilter({
              ...usersFilter,
              region: "",
              city: "",
              italy_region: "",
              provincia: "",
              comune: "",
              country: ""
            });
          } else {
            if (e.target.value === "107") {
              setUsersFilter({
                ...usersFilter,
                region: "",
                city: "",
                country: e.target.value
              });
            } else if (e.target.value !== "107") {
              setUsersFilter({
                ...usersFilter,
                italy_region: "",
                provincia: "",
                comune: "",
                country: e.target.value
              });
            }
          }
        }} >
          <option selected value="">All</option>
          {
            countriesUserFilter && countriesUserFilter?.length > 0 && countriesUserFilter?.map(c => (
              <option key={c?._id} value={c?.id}>{c?.name}</option>
            ))
          }
        </Input>
      </FormGroup>
      {
        usersFilter.country === "107" ?
          <React.Fragment>
            <FormGroup>
              <Label>Region</Label>
              <Input type="select" value={usersFilter.italy_region} onChange={e => {
                setUsersFilter({ ...usersFilter, italy_region: e.target.value });
                if (e.target.value === "") {
                  setUsersFilter({
                    ...usersFilter,
                    provincia: "",
                    comune: ""
                  })
                }
              }} >
                <option selected value="">All</option>
                {
                  regionsUserFilter && regionsUserFilter?.length > 0 && regionsUserFilter?.map(r => (
                    <option key={r?._id} value={r?.IdRegione}>{r?.DescrRegione}</option>
                  ))
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Provincia</Label>
              <Input type="select" value={usersFilter.provincia} onChange={e => {
                setUsersFilter({ ...usersFilter, provincia: e.target.value });
                if (e.target.value === "") {
                  setUsersFilter({
                    ...usersFilter,
                    comune: ""
                  })
                }
              }} >
                <option selected value="">All</option>
                {
                  provinciasUserFilter && provinciasUserFilter?.length > 0 && provinciasUserFilter?.map(p => (
                    <option key={p?._id} value={p?.IdProvincia}>{p?.DescrProvincia}</option>
                  ))
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Comune</Label>
              <Input type="select" value={usersFilter.comune} onChange={e => setUsersFilter({ ...usersFilter, comune: e.target.value })} >
                <option selected value="">All</option>
                {
                  comunesUserFilter && comunesUserFilter?.length > 0 && comunesUserFilter?.map(c => (
                    <option key={c?._id} value={c?.IdComune}>{c?.DescrComune}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </React.Fragment> :
          <React.Fragment>
            <FormGroup>
              <Label>State</Label>
              <Input type="select" value={usersFilter.region} onChange={e => {
                setUsersFilter({ ...usersFilter, region: e.target.value });
                if (e.target.value === "") {
                  setUsersFilter({ ...usersFilter, city: "" });
                }
              }} >
                <option selected value="">All</option>
                {
                  statesUserFilter && statesUserFilter?.length > 0 && statesUserFilter?.map(s => (
                    <option key={s?._id} value={s?.id}>{s?.name}</option>
                  ))
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>City</Label>
              <Input type="select" value={usersFilter.city} onChange={e => setUsersFilter({ ...usersFilter, city: e.target.value })} >
                <option selected value="">All</option>
                {
                  citiesUserFilter && citiesUserFilter?.length > 0 && citiesUserFilter?.map(c => (
                    <option key={c?._id} value={c?.id}>{c?.name}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </React.Fragment>
      }
      <hr />

      <p>Other</p>
      <FormGroup>
        <Label>Civil</Label>
        <Input type="select" value={usersFilter.civil} onChange={e => setUsersFilter({ ...usersFilter, civil: e.target.value })}>
          <option selected value="">All</option>
          {
            civilStateList && civilStateList?.length > 0 && civilStateList?.map(c => (
              <option key={c?._id} value={c?.type}>{c?.type}</option>
            ))
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Education</Label>
        <Input type="select" value={usersFilter.education} onChange={e => setUsersFilter({ ...usersFilter, education: e.target.value })}>
          <option selected value="">All</option>
          {
            educationList && educationList?.length > 0 && educationList?.map(c => (
              <option key={c?._id} value={c?.type}>{c?.type}</option>
            ))
          }
        </Input>
      </FormGroup>
      <hr />

      <p>Attended Survey</p>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterAttend" checked={usersFilter.attendedSurvey === 1} onChange={e => setUsersFilter({ ...usersFilter, attendedSurvey: 1 })} />{' '}
          Attended minimum one survey.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterAttend" checked={usersFilter.attendedSurvey === 0} onChange={e => setUsersFilter({ ...usersFilter, attendedSurvey: 0 })} />{' '}
          Didn't attended any surveys.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterAttend" checked={usersFilter.attendedSurvey === null} onChange={e => setUsersFilter({ ...usersFilter, attendedSurvey: null })} />{' '}
          All
        </Label>
      </FormGroup>
      <hr />

      <p>Created Survey</p>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterCreate" checked={usersFilter.createdSurvey === 1} onChange={e => setUsersFilter({ ...usersFilter, createdSurvey: 1 })} />{' '}
          Created minimum one survey.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterCreate" checked={usersFilter.createdSurvey === 0} onChange={e => setUsersFilter({ ...usersFilter, createdSurvey: 0 })} />{' '}
          Didn't Created any surveys.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="surveyFilterCreate" checked={usersFilter.createdSurvey === null} onChange={e => setUsersFilter({ ...usersFilter, createdSurvey: null })} />{' '}
          All
        </Label>
      </FormGroup>
      <hr />

      <p>Created Group</p>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="groupFilterCreate" checked={usersFilter.createdGroup === 1} onChange={e => setUsersFilter({ ...usersFilter, createdGroup: 1 })} />{' '}
          Created minimum one Group.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="groupFilterCreate" checked={usersFilter.createdGroup === 0} onChange={e => setUsersFilter({ ...usersFilter, createdGroup: 0 })} />{' '}
          Didn't Created any Group.
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input type="radio" name="groupFilterCreate" checked={usersFilter.createdGroup === null} onChange={e => setUsersFilter({ ...usersFilter, createdGroup: null })} />{' '}
          All
        </Label>
      </FormGroup>
      <Row className="mt-3">
        <Col md="6">
          <Button outline color="warning" style={{ width: "100%" }} onClick={e => handleClearAllFilter()} data-bs-dismiss="offcanvas" aria-label="Close">Clear All</Button>
        </Col>
        <Col md="6">
          <Button outline color="success" style={{ width: "100%" }} onClick={e => handleApplyFilter()} data-bs-dismiss="offcanvas" aria-label="Close">Apply Filter</Button>
        </Col>
      </Row>
    </React.Fragment>
  )
};

export default FilterBody;