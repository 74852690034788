import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import * as dropdownActions from "../../redux/dropdown/dropdown.actions";
import { toast } from "react-toastify";

const AddQuestionModal = ({ addQuestionModal, addQuestionToggle, surveyActions, surveyId }) => {

  const [question, setQuestion] = useState({
    surveyId: "",
    questionType: "",
    title: "",
    options: ["", ""],
    counts: [],
    mandatory: false,
    comment: false
  });
  const dispatch = useDispatch();

  const questionType = useSelector(state => state.dropdownInfo.questionType);

  useEffect(() => {
    setQuestion(question);
    dispatch(dropdownActions.questionTypeList());
  }, []);

  const handleAddQuestion = () => {
    if (question.questionType === "" || question.title === "" || question.options.length < 1) {
      toast("Please fill fields");
      return;
    }
    if (question.questionType === "6310838a7795bd3a2dfc729b") {
      dispatch(surveyActions.adminAddSurveyQuestion({ ...question, surveyId, options: [1, 2, 3, 4, 5], counts: [0, 0, 0, 0, 0] }, addQuestionToggle, setQuestion));
    } else {
      dispatch(surveyActions.adminAddSurveyQuestion({ ...question, surveyId, counts: Array(question.options.length).fill(0) }, addQuestionToggle, setQuestion));
    }
  };

  const handleAddRow = () => {
    const options = [...question.options, ""];
    setQuestion({ ...question, options });
  };

  const handleDeleteRow = (index) => {
    setQuestion({
      ...question,
      options: question.options.filter((d, i) => i !== index)
    });
  };

  return (
    <React.Fragment>
      <Modal isOpen={addQuestionModal} toggle={addQuestionToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Add Survey Question</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Row>
            <Col md="12">
              <FormGroup>
                <Input type="select" value={question.questionType} onChange={e => setQuestion({ ...question, questionType: e.target.value })}>
                  <option value="" selected disabled>Select Question Type</option>
                  {
                    questionType && questionType?.length > 0 && questionType?.map(q => (
                      <option key={q?._id} value={q?._id}>{q?.type}</option>
                    ))
                  }
                </Input>
              </FormGroup>
              <FormGroup>
                <Input type="text" placeholder="Title" value={question.title} onChange={e => setQuestion({ ...question, title: e.target.value })} />
              </FormGroup>
              {
                question.questionType === "6310838a7795bd3a2dfc729b" &&
                <React.Fragment>
                  <h4 className="mt-3 mb-3">⭐⭐⭐⭐⭐</h4>
                </React.Fragment>
              }
              {
                ((question.questionType === "631083877795bd3a2dfc7295") || (question.questionType === "631083807795bd3a2dfc728f")) &&
                <React.Fragment>
                  <FormGroup>
                    {
                      question.options && question.options?.length > 0 && question.options?.map((p, i) => {
                        return (
                          <Row className="mt-3" key={i}>
                            <Col md="12">
                              <Input
                                type="text"
                                value={p}
                                placeholder={`Option ${i + 1}`}
                                onChange={(e) => {
                                  question.options[i] = e.target.value;
                                  setQuestion({ ...question });
                                }}
                              />
                            </Col>
                            {
                              question.options?.length > 1 &&
                              <Col md="3" className="my-3">
                                <Button color="warning" onClick={() => handleDeleteRow(i)}>
                                  <i className="fa-solid fa-trash"></i>{" "}
                                  Delete Option
                                </Button>
                              </Col>
                            }

                            {
                              i === (question.options?.length - 1) &&
                              <Col md="3" className="my-3">
                                <Button color="success" onClick={(e) => handleAddRow()}>
                                  <i className="fa-solid fa-plus"></i> Add Options
                                </Button>
                              </Col>
                            }
                          </Row>
                        )
                      })
                    }
                  </FormGroup>
                </React.Fragment>
              }
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Input
                      name="commentModal"
                      type="checkbox"
                      value={question.comment}
                      onChange={e => setQuestion({ ...question, comment: !question.comment })}
                    />
                    {' '}
                    <Label check>
                      Comment
                    </Label>
                  </Col>
                  <Col md="6">
                    <Input
                      name="mandatoryModal"
                      type="checkbox"
                      value={question.mandatory}
                      onChange={e => setQuestion({ ...question, mandatory: !question.mandatory })}
                    />
                    {' '}
                    <Label check>
                      Mandatory
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            addQuestionToggle();
            setQuestion({
              surveyId: "",
              questionType: "",
              title: "",
              options: [],
              counts: [],
              mandatory: false,
              comment: false
            });
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleAddQuestion()
          }}>Add</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default AddQuestionModal;