import React from "react";
import { Col, Input, Row } from "reactstrap";

const Comment = () => {
  return (
    <React.Fragment>
      <Row className="mt-4 px-4">
        <Col md="1">
          <div style={{ float: "right", marginTop: "10px" }}>
            <p>Search</p>
          </div>
        </Col>
        <Col>
          <div className="search__header__input">
            <Input
              placeholder="Title or Created User"
              type="search"
              autoFocus
            />
          </div>
        </Col>
        <Col md="2">
          {" "}
          <button
            class="btn btn-primary w-100 h-75 mb-3"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <i class="fa-solid fa-sliders me-3"></i>
            Filter
          </button>
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <h5 id="offcanvasRightLabel">Filter</h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">...</div>
          </div>
        </Col>
      </Row>
      <div className="row mt-4">
        <div className="col-md-4 col-sm-12">
          <div className="row m-auto">
            <div className="col-12 mt-4">
              <p className="mb-2">User :</p>
              <div className="col-12">
                <input
                  type="text"
                  class="form-control "
                  id="formGroupExampleInput"
                  placeholder="user34534"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mt-4">
                    <textarea
                      class="form-control w-75"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style={{ height: "100px" }}
                    ></textarea>
                  </div>
            <div className="col-12 mt-3">
              <p className="mb-2">date :</p>
              <div className="col-12">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="user34534"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <p className="mb-2">Reply to :</p>
              <div className="col-12">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="user34534"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <p className="mb-2">In vote :</p>
              <div className="col-12">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="user34534"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-lg-7 col-md-9 col-sm-12">
                  <div className="col-12 mt-5 d-flex justify-content-start">
                  <i class="fa fa-2x fa-pencil" aria-hidden="true"></i>
                  <i class="fa fa-2x fa-trash ms-5" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-5" style={{ paddingLeft: "32px" }}>
              <div className="row">
                <div className="col-3">
                  <p style={{fontSize: '25px'}}><i class="fa fa-thumbs-up me-3" aria-hidden="true"></i>2</p>
                </div>
                <div className="col-3">
                  <p style={{fontSize: '25px'}}><i class="fa fa-thumbs-down me-3" aria-hidden="true"></i>6</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-12"
          style={{ paddingLeft: "32px", paddingRight: "2rem" }}
        >
          <hr className="mt-4 mt-5 mb-2" />
        </div>
        <div className="col-12" style={{ paddingLeft: "32px" }}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mt-4 d-flex align-items-center">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control w-50 mx-3"
                id="formGroupExampleInput"
                placeholder="458"
              />
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <i class="fa fa-2x fa-trash mx-5" aria-hidden="true"></i>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12 mt-4 d-flex justify-content-start align-items-center">
              <i class="fa fa-print fa-2x" aria-hidden="true"></i>
              <i class="fa fa-2x fa-users ms-4" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Comment;
