import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const EditPreliminary = ({ editPreliminaryModal, editPreliminaryToggle, surveyActions, surveyDetails, surveyIndex, preliminaryIndex, questionData }) => {

  const [question, setQuestion] = useState({
    surveyId: surveyDetails?._id,
    question: "",
    correctAnswer: "",
    options: [""],
    points: [0],
    maxTime: null,
    mandatory: false
  });
  const [survey, setSurvey] = useState({
    requiredPoints: null
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestion({
      surveyId: questionData?.surveyId,
      question: questionData?.question,
      correctAnswer: questionData?.correctAnswer,
      options: questionData?.options,
      points: questionData?.points,
      maxTime: questionData?.maxTime,
      mandatory: questionData?.mandatory
    });
    setSurvey({
      requiredPoints: surveyDetails?.requiredPoints
    });
  }, [questionData, surveyDetails]);

  const handleEditPreliminary = () => {
    dispatch(surveyActions.adminEditSurveyPreliminary(questionData?._id, { question: { ...question, surveyId: surveyDetails?._id }, survey: { ...survey, requiredPoints: survey?.requiredPoints } }, editPreliminaryToggle));
  };

  const handleAddRow = () => {
    if (surveyDetails.preliminaryQuestionType === "direct elimination") {
      const options = [...question.options, ""];
      setQuestion({ ...question, options });
    } else if (surveyDetails.preliminaryQuestionType === "point system") {
      const options = [...question.options, ""];
      const points = [...question.points, 0];
      setQuestion({ ...question, options, points });
    }
  };

  const handleDeleteRow = (index) => {
    if (surveyDetails.preliminaryQuestionType === "direct elimination") {
      setQuestion({
        ...question,
        options: question.options.filter((d, i) => i !== index)
      });
    } else if (surveyDetails.preliminaryQuestionType === "point system") {
      setQuestion({
        ...question,
        options: question.options.filter((d, i) => i !== index),
        points: question.points.filter((d, i) => i !== index)
      });
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={editPreliminaryModal} toggle={editPreliminaryToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Edit Survey Question</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          {
            surveyDetails?.preliminaryQuestionType === "direct elimination" &&
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Input type="text" placeholder="Question" value={question.question} onChange={e => setQuestion({ ...question, question: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    {
                      question.options && question.options?.length > 0 && question.options?.map((p, i) => {
                        return (
                          <Row className="mt-3" key={i}>
                            <Col md="12">
                              <Input
                                type="text"
                                value={p}
                                placeholder={`Option ${i + 1}`}
                                onChange={(e) => {
                                  question.options[i] = e.target.value;
                                  setQuestion({ ...question });
                                }}
                              />
                            </Col>
                            {
                              question.options?.length > 1 &&
                              <Col md="3" className="my-3">
                                <Button color="warning" onClick={() => handleDeleteRow(i)}>
                                  <i className="fa-solid fa-trash"></i>{" "}
                                  Delete Option
                                </Button>
                              </Col>
                            }

                            {
                              i === (question.options?.length - 1) &&
                              <Col md="3" className="my-3">
                                <Button color="success" onClick={(e) => handleAddRow()}>
                                  <i className="fa-solid fa-plus"></i> Add Options
                                </Button>
                              </Col>
                            }
                          </Row>
                        )
                      })
                    }
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Input type="select" value={question.correctAnswer} onChange={e => setQuestion({ ...question, correctAnswer: e.target.value })}>
                      <option value="" selected disabled>Select Correct Answer</option>
                      {
                        question.options && question.options?.length > 0 && question.options?.map(c => (
                          <option key={c} value={c}>{c}</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Input type="select" value={question.maxTime} onChange={e => setQuestion({ ...question, maxTime: Number(e.target.value) })}>
                    <option value={null}>Enter Max Time (Seconds)</option>
                    <option value={45}>45</option>
                    <option value={60}>60</option>
                    <option value={100}>100</option>
                    <option value={120}>120</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                    <option value={300}>300</option>
                  </Input>
                </Col>
                <Col md="12" style={{ marginTop: "10px" }}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Input
                          name="mandatoryModal"
                          type="checkbox"
                          checked={question.mandatory}
                          onChange={e => setQuestion({ ...question, mandatory: !question.mandatory })}
                        />
                        {' '}
                        <Label check>
                          Mandatory
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          }
          {
            surveyDetails?.preliminaryQuestionType === "point system" &&
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Input type="text" placeholder="Question" value={question.question} onChange={e => setQuestion({ ...question, question: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    {
                      question.options && question.options?.length > 0 && question.options?.map((p, i) => {
                        return (
                          <Row className="mt-3" key={i}>
                            <Col md="10">
                              <Input
                                type="text"
                                value={p}
                                placeholder={`Option ${i + 1}`}
                                onChange={(e) => {
                                  question.options[i] = e.target.value;
                                  setQuestion({ ...question });
                                }}
                              />
                            </Col>
                            <Col md="2">
                              <Input
                                type="select"
                                defaultValue={question.options[i]}
                                onChange={(e) => {
                                  question.points[i] = Number(e.target.value);
                                  setQuestion({ ...question });
                                }}
                              >
                                <option value={0} selected>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                              </Input>
                            </Col>
                            {
                              question.options?.length > 1 &&
                              <Col md="3" className="my-3">
                                <Button color="warning" onClick={() => handleDeleteRow(i)}>
                                  <i className="fa-solid fa-trash"></i>{" "}
                                  Delete Option
                                </Button>
                              </Col>
                            }

                            {
                              i === (question.options?.length - 1) &&
                              <Col md="3" className="my-3">
                                <Button color="success" onClick={(e) => handleAddRow()}>
                                  <i className="fa-solid fa-plus"></i> Add Options
                                </Button>
                              </Col>
                            }
                          </Row>
                        )
                      })
                    }
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Input type="number" placeholder="Required Points" value={survey.requiredPoints} onChange={e => setSurvey({ ...survey, requiredPoints: Number(e.target.value) })} />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Input type="select" value={question.maxTime} onChange={e => setQuestion({ ...question, maxTime: Number(e.target.value) })}>
                      <option value={null}>Enter Max Time (Seconds)</option>
                      <option value={45}>45</option>
                      <option value={60}>60</option>
                      <option value={100}>100</option>
                      <option value={120}>120</option>
                      <option value={150}>150</option>
                      <option value={200}>200</option>
                      <option value={300}>300</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12" style={{ marginTop: "10px" }}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Input
                          name="mandatoryModal"
                          type="checkbox"
                          checked={question.mandatory}
                          onChange={e => setQuestion({ ...question, mandatory: !question.mandatory })}
                        />
                        {' '}
                        <Label check>
                          Mandatory
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            editPreliminaryToggle();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleEditPreliminary()
          }}>Update</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default EditPreliminary;