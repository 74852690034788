import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as optionHtmlDocActions from "../../redux/optionHtmlDoc/htmlDoc.actions";
import { useRef } from 'react';
import { useState } from 'react';
import JoditEditor from 'jodit-react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Comment from '../optionHtmlDoc/Comment';
import "../optionHtmlDoc/htmlDoc.css";

const QuestionOptionDocModal = ({ optionHtmlModal, optionHtmlToggle, uuid }) => {

  const [content, setContent] = useState('');
  const [commentModal, setCommentModal] = useState(false);
  const editor = useRef(null);
  const dispatch = useDispatch();

  const commentToggle = () => setCommentModal(!commentModal);

  const doc = useSelector(state => state.htmlDocInfo.doc);
  const isCommentStatusChange = useSelector(state => state.htmlDocInfo.isCommentStatusChange);
  const commentList = useSelector(state => state.htmlDocInfo.commentList);
  const user = useSelector(state => state.userInfo.user);
  const prevProps = useRef({ isCommentStatusChange }).current;

  useEffect(() => {
    dispatch(optionHtmlDocActions.getOptionHtmlDoc(uuid));
  }, [uuid]);

  useEffect(() => {
    if (Object.keys(doc)?.length > 0) {
      dispatch(optionHtmlDocActions.listCommentHtmlDoc(doc?._id));
    }
  }, [doc]);

  useEffect(() => {
    if (prevProps.isCommentStatusChange !== isCommentStatusChange) {
      if (isCommentStatusChange) {
        dispatch(optionHtmlDocActions.listCommentHtmlDoc(doc?._id));
      }
    }
    return () => {
      prevProps.isCommentStatusChange = isCommentStatusChange;
    }
  }, [isCommentStatusChange]);

  useEffect(() => {
    if (Object.keys(doc)?.length > 0) {
      setContent(doc?.html);
    }
  }, [doc]);

  const config = {
    placeholder: "Start Typing......",
    "toolbar": true,
    "showCharsCounter": false,
    "showWordsCounter": false,
    "showXPathInStatusbar": false,
    readonly: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: true, //couse styling issues when pasting from word
    events: {
      afterOpenPasteDialog: (dialog, msg, title, callback) => {
        dialog.close()
        callback()
      },
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={optionHtmlModal} toggle={optionHtmlToggle} backdrop={"static"} className="modal-xl" centered>
        <ModalHeader style={{ borderBottom: "none" }}>
          <span aria-hidden="true" style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer", fontSize: "20px" }} onClick={e => optionHtmlToggle()}>x</span>
        </ModalHeader>
        <ModalBody>
        <Button color='success' className='my-3' onClick={e => commentToggle()}>Comments</Button>
          <React.Fragment>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
            />
          </React.Fragment>
        </ModalBody>
      </Modal>
      <Comment commentModal={commentModal} commentToggle={commentToggle} doc={doc} commentList={commentList} user={user} />
    </React.Fragment>
  )
};

export default QuestionOptionDocModal;