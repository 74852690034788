import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Col, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { awsBaseUrl } from "../../helper";
import * as surveyActions from "../../redux/survey/survey.actions";
import * as dropdownActions from "../../redux/dropdown/dropdown.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditSurveySettingModal from "./EditSurveySettingModal";
import AddSponsorModal from "./AddSponsorModal";
import AddQuestionModal from "./AddQuestionModal";
import EditQuestionModal from "./EditSurveyQuestion";
import AddOptionModal from "./AddOption";
import AddPreliminary from "./AddPreliminaryModal";
import EditPreliminary from "./EditPreliminaryModal";
import AddPreliminaryOptionModal from "./AddPreliminaryOptionModal";
import axios from "axios";
import Loader from "../../layouts/loader/Loader";
import AppSelectionModal from "./AppSelectionModal";
import SurveyFilterBody from "./SurveyFilterBody";
import EditSurveyPrize from "./EditSurveyPrize";
import EditSurveySponsor from "./EditSurveySponsor";
import ConfirmDeleteQuestion from "./ConfirmDeleteQuestion";
import FilterUser from "../filterComponents/FilterUser";
import QuestionOptionDocModal from "./QuestionOptionDocModal";

const Surveys = () => {

  const [search, setSearch] = useState("");
  const [surveySettingModal, setSurveySettingModal] = useState(false);
  const [questionState, setQuestionState] = useState("questions");
  const [surveyIndex, setSurveyIndex] = useState(0);
  const [sponsorIndex, setSponsorIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [preliminaryIndex, setPreliminaryIndex] = useState(0);
  const [selectSponsorImg, setSelectSponsorImg] = useState("");
  const [selectPrizeImg, setSelectPrizeImg] = useState("");
  const [addSponsorModal, setAddSponsorModal] = useState(false);
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [addOptionModal, setAddOptionModal] = useState(false);
  const [addPreliminaryModal, setAddPreliminaryModal] = useState(false);
  const [editPreliminaryModal, setEditPreliminaryModal] = useState(false);
  const [addPreliminaryOptionModal, setAddPreliminaryOptionModal] = useState(false);
  const [surveyAppSelectionModal, setSurveyAppSelectionModal] = useState(false);
  const [editSurveyPrizeModal, setEditSurveyPrizeModal] = useState(false);
  const [editSurveySponsorModal, setEditSurveySposnorModal] = useState(false);
  const [deleteSurveyQuestionModal, setDeleteSurveyQuestionModal] = useState(false);
  const [deleteSurveyModal, setDeleteSurveyModal] = useState(false);
  const [stopSurveyModal, setStopSurveyModal] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [targetLoading, setTargetLoading] = useState(false);
  const [filterComponent, setFilterComponent] = useState(false);
  const [optionHtmlModal, setOptionHtmlModal] = useState(false);
  const [filterComponentState, setFilterComponentState] = useState({});
  const [uuid, setUuid] = useState("");
  const [surveyFilters, setSurveyFilters] = useState({
    surveyStatus: null,
    fromDate: "",
    toDate: ""
  });
  const inputFileRefPrize = useRef(null);
  const inputFileRefSponsor = useRef(null);

  const surveySettingToggle = () => setSurveySettingModal(!surveySettingModal);
  const addSponsorToggle = () => setAddSponsorModal(!addSponsorModal);
  const addQuestionToggle = () => setAddQuestionModal(!addQuestionModal);
  const editQuestionToggle = () => setEditQuestion(!editQuestion);
  const addOptionToggle = () => setAddOptionModal(!addOptionModal);
  const addPreliminaryToggle = () => setAddPreliminaryModal(!addPreliminaryModal);
  const editPreliminaryToggle = () => setEditPreliminaryModal(!editPreliminaryModal);
  const addPreliminaryOptionToggle = () => setAddPreliminaryOptionModal(!addPreliminaryOptionModal);
  const surveyAppSelectionToggle = () => setSurveyAppSelectionModal(!surveyAppSelectionModal);
  const editSurveyPrizeToggle = () => setEditSurveyPrizeModal(!editSurveyPrizeModal);
  const editSurveySponsorToggle = () => setEditSurveySposnorModal(!editSurveySponsorModal);
  const deleteSurveyQuestionToggle = () => setDeleteSurveyQuestionModal(!deleteSurveyQuestionModal);
  const deleteSurveyToggle = () => setDeleteSurveyModal(!deleteSurveyModal);
  const stopSurveyToggle = () => setStopSurveyModal(!stopSurveyModal);
  const optionHtmlToggle = () => {
    if (optionHtmlModal) {
      setUuid("");
    }
    setOptionHtmlModal(!optionHtmlModal);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const loading = useSelector(state => state.surveyInfo.loading);
  const surveysListDetails = useSelector(state => state.surveyInfo.surveysListDetails);
  const isSurveyStatusChange = useSelector(state => state.surveyInfo.isSurveyStatusChange);
  const surveyAppSelectionCount = useSelector(state => state.surveyInfo.surveyAppSelectionCount);
  const questionType = useSelector(state => state.dropdownInfo.questionType);
  const prevProps = useRef({ isSurveyStatusChange }).current;

  useEffect(() => {
    if (location?.state !== null) {
      dispatch(surveyActions.getSurveyListDetails(search, location?.state?.id, location?.state?.users, location?.state?.surveys, location?.state?.groups, surveyFilters));
    } else {
      dispatch(surveyActions.getSurveyListDetails(search, "", [], [], [], surveyFilters));
    }
    dispatch(dropdownActions.questionTypeList());
  }, [location]);

  useEffect(() => {
    if (location?.state !== null) {
      dispatch(surveyActions.getSurveyListDetails(search, location?.state?.id, location?.state?.users, location?.state?.surveys, location?.state?.groups, surveyFilters));
    } else {
      dispatch(surveyActions.getSurveyListDetails(search, "", [], [], [], surveyFilters));
    }
  }, [search]);

  useEffect(() => {
    if (surveysListDetails[surveyIndex]?.surveyAppSelection[0]) {
      dispatch(surveyActions.surveyAppSelectionCount({ surveys: [surveysListDetails[surveyIndex]?._id] }));
    } else {
      dispatch(surveyActions.clearsurveyAppSelectionCount());
    }
  }, [surveysListDetails, surveyIndex]);

  useEffect(() => {
    if (prevProps.isSurveyStatusChange !== isSurveyStatusChange) {
      if (isSurveyStatusChange) {
        if (location?.state !== null) {
          dispatch(surveyActions.getSurveyListDetails(search, location?.state?.id, location?.state?.users, location?.state?.surveys, location?.state?.groups, surveyFilters));
        } else {
          dispatch(surveyActions.getSurveyListDetails(search, "", [], [], [], surveyFilters));
        }
      }
    }

    return () => {
      prevProps.isSurveyStatusChange = isSurveyStatusChange;
    }
  }, [isSurveyStatusChange]);

  const handlePreviousSurveyClick = () => {
    if (surveyIndex !== 0) {
      setSurveyIndex(surveyIndex - 1);
      setSponsorIndex(0);
      setQuestionIndex(0);
      setQuestionState("questions");
      setPreliminaryIndex(0);
    }
  };

  const handleNextSurveyClick = () => {
    if (surveyIndex !== surveysListDetails?.length - 1) {
      setSurveyIndex(surveyIndex + 1);
      setSponsorIndex(0);
      setQuestionIndex(0);
      setQuestionState("questions");
      setPreliminaryIndex(0);
    }
  };

  const handleClickPreviousSponsor = () => {
    setSponsorIndex(sponsorIndex - 1);
  };

  const handleClickNextSponsor = () => {
    setSponsorIndex(sponsorIndex + 1);
  };

  const handleClickPreviousQuestion = () => {
    setQuestionIndex(questionIndex - 1);
  };

  const handleClickNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
  };

  const handleClickPreviousPreliminaryQuestion = () => {
    setPreliminaryIndex(preliminaryIndex - 1);
  };

  const handleClickNextPreliminaryQuestion = () => {
    setPreliminaryIndex(preliminaryIndex + 1);

  };

  const handleNavigateListOfSurveyOrganizers = () => {
    // const users = surveysListDetails?.map(s => s?.createdBy);
    // navigate('/user', { state: { id: "", users, emptyFilterUsers: false } });
    setFilterComponent(true);
    setFilterComponentState({
      id: "",
      users: surveysListDetails?.map(s => s?.createdBy),
      emptyFilterUsers: false
    });
  };

  const handleNavigateListOfSurveyAttendees = () => {
    let users;
    if (surveysListDetails?.length > 1) {
      users = surveysListDetails?.map(s => s?.users);
      users = users.reduce((p, c) => p.filter(e => c.includes(e)));
    } else {
      users = surveysListDetails?.map(s => s?.users);
      users = [].concat.apply([], users);
    }
    // navigate('/user', { state: { id: "", users: users, emptyFilterUsers: users?.length > 0 ? false : true } });
    setFilterComponent(true);
    setFilterComponent({
      id: "",
      users: users,
      emptyFilterUsers: users?.length > 0 ? false : true
    });
  };

  const handleStopSurvey = () => {
    if (!surveysListDetails[surveyIndex]?.active) {
      toast("Survey Already Stopped.");
      return;
    }
    dispatch(surveyActions.stopSurvey(surveysListDetails[surveyIndex]?._id));
    stopSurveyToggle();
  };

  const checkSurveyAttendOrStopped = () => {
    if (!surveysListDetails[surveyIndex]?.active) {
      toast("You can't edit stopped survey.");
      return false;
    }

    if (surveysListDetails[surveyIndex]?.users?.length > 0) {
      toast("You can't edit attended survey.");
      return false;
    }
    return true;
  };

  const handleDeleteSurvey = () => {
    if (surveysListDetails[surveyIndex]?.deleted) {
      toast("Survey Already Deleted");
      return;
    }
    dispatch(surveyActions.deleteSurvey(surveysListDetails[surveyIndex]?._id));
    deleteSurveyToggle();
  };

  const handleNavigateToAttemptedUsersPage = () => {
    if (surveysListDetails[surveyIndex]?.users?.length === 0) {
      toast("No Attended Users");
      return;
    }
    // navigate('/user', { state: { id: "", users: surveysListDetails[surveyIndex]?.users, emptyFilterUsers: false } });
    setFilterComponent(true);
    setFilterComponentState({
      id: "",
      users: surveysListDetails[surveyIndex]?.users,
      emptyFilterUsers: false
    });
  };

  const handleNavigateToResultPage = () => {
    if (surveysListDetails[surveyIndex].publish === false) {
      toast("Survey is not published");
      return;
    }
    if (surveysListDetails[surveyIndex]?.surveySettings?.liveResult === false) {
      if (surveysListDetails[surveyIndex]?.active === true) {
        toast("Survey is not Ended.");
        return;
      }
    }
    // navigate(`/survey/result/${surveysListDetails[surveyIndex]?._id}?userId=`);
    window.open(`${process.env.REACT_APP_SURVEY_RESULT_BASE_URL}survey-results/${surveysListDetails[surveyIndex]?._id}?userId=`, '_blank');
  };

  const clickChangeSponsorImage = () => {
    inputFileRefSponsor.current.click();
  };

  const onFileChangeSponsor = (e) => {
    setSelectSponsorImg(e.target.files[0]);
    dispatch(surveyActions.adminUpdateSurveySponsorImage(surveysListDetails[surveyIndex]?.sponsor[sponsorIndex]?._id, { file: e.target.files[0] }));
  };

  const clickChangePrizeImage = () => {
    inputFileRefPrize.current.click();
  };

  const onFileChangePrize = (e) => {
    setSelectPrizeImg(e.target.files[0]);
    dispatch(surveyActions.adminUpdateSurveyPrizeImage(surveysListDetails[surveyIndex]?.prize[0]?._id, { file: e.target.files[0] }));
  };

  const handleRedirectToTargetSelectionUsersByAllListSurveys = async () => {
    try {
      setTargetLoading(true);
      const surveys = surveysListDetails && surveysListDetails?.length > 0 && surveysListDetails?.map(s => s?._id);
      if (surveys?.length === 0) {
        toast("No surveys");
        setTargetLoading(false);
        return;
      }
      if (surveysListDetails?.length === 1) {
        if (surveysListDetails[0]?.surveyAppSelection?.length === 0) {
          toast("App selection not selected.");
          setTargetLoading(false);
          return;
        }
      }
      const url = `${process.env.REACT_APP_BASE_URL}/admin/get/survey/target/slection/users/v1`;
      const response = await axios.post(url, { surveys }, {
        headers: {
          Authorization: localStorage.getItem('Authorization')
        }
      });
      setTargetLoading(false);
      // navigate('/user', { state: { id: "", users: response?.data?.data, emptyFilterUsers: false } });
      setFilterComponent(true);
      setFilterComponentState({
        id: "",
        users: response?.data?.data,
        emptyFilterUsers: false
      });
    } catch (error) {
      setTargetLoading(false);
      console.log(error);
      toast(error?.message);
    }
  };

  return (
    <React.Fragment>
      {
        filterComponent ?
          <FilterUser state={filterComponentState} setFilterComponentBack={setFilterComponent} /> :
          <React.Fragment>
            {
              targetLoading || loading ?
                <Loader /> :
                <React.Fragment>
                  <Row className="mt-4 px-4">
                    <Col md="1">
                      <div style={{ float: "right", marginTop: "10px" }}>
                        <p>Search</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="search__header__input">
                        <Input
                          placeholder="Search Title or Created User"
                          type="search"
                          value={search}
                          onChange={e => {
                            setSearch(e.target.value);
                            setSurveyIndex(0);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      {" "}
                      {
                        surveysListDetails && surveysListDetails?.length > 0 &&
                        <React.Fragment>
                          <button
                            className="btn btn-primary w-100 h-75 mb-3"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            <i className="fa-solid fa-sliders me-3"></i>
                            Filter
                          </button>
                          <div
                            className="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel"
                          >
                            <div className="offcanvas-header">
                              <h5 id="offcanvasRightLabel">Filter</h5>
                              <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="offcanvas-body">
                              <SurveyFilterBody filter={surveyFilters} setFilter={setSurveyFilters} state={location?.state} surveyActions={surveyActions} search={search} setIndex={setSurveyIndex} />
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    </Col>
                  </Row>
                  <div className="row mt-4">
                    {
                      surveysListDetails && surveysListDetails?.length > 0 ?
                        <React.Fragment>
                          <div className="col-md-8 col-sm-12">
                            <div className="row">
                              <div className="col-md-4 col-sm-12" style={{ paddingLeft: "32px" }}>
                                <div className="row">
                                  <div className="col-12">
                                    <div style={{ maxHeight: "175px" }} className="">
                                      <img
                                        src={`${awsBaseUrl}${surveysListDetails[surveyIndex]?.banner}`}
                                        style={{ objectFit: "cover", maxHeight: "175px" }}
                                        className="w-100"
                                        alt=""
                                        srcset=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={surveysListDetails[surveyIndex]?.title}
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={surveysListDetails[surveyIndex]?.description}
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={surveysListDetails[surveyIndex]?.surveySettings?.thanks}
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="surveyPrivacy"
                                            id="privacy"
                                            checked={surveysListDetails[surveyIndex]?.surveySettings?.private}
                                          />
                                          <label className="form-check-label" for="privacy">
                                            Private
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="surveyPrivacy"
                                            id="privacy2"
                                            checked={!surveysListDetails[surveyIndex]?.surveySettings?.private}
                                          />
                                          <label className="form-check-label" for="privacy2">
                                            Public
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        checked={surveysListDetails[surveyIndex]?.surveySettings?.anonymity}
                                      />
                                      <label className="form-check-label" for="flexCheckDefault">
                                        Allow anonymity
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="result"
                                            id="result"
                                            checked={surveysListDetails[surveyIndex]?.surveySettings?.liveResult}
                                          />
                                          <label className="form-check-label" for="result">
                                            Live results
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="result"
                                            id="result2"
                                            checked={!surveysListDetails[surveyIndex]?.surveySettings?.liveResult}
                                          />
                                          <label className="form-check-label" for="result2">
                                            Results at the end
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                    <i className="fa-solid fa-pen-to-square" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                      checkSurveyAttendOrStopped() &&
                                        setSurveySettingModal(true);
                                    }}></i>
                                    <i className="fa-sharp fa-solid fa-share-nodes" aria-hidden="true" style={{ cursor: "pointer" }}></i>
                                    <i className={`fa fa-stop-circle ${!surveysListDetails[surveyIndex]?.active && 'text-muted'}`} aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => setStopSurveyModal(true)}></i>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => setDeleteSurveyModal(true)}></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="row">
                                  <div className="col-12">
                                    <label for="validationCustom04" className="form-label">
                                      Organizer
                                    </label>
                                    <InputGroup>
                                      <input
                                        type="text"
                                        className="form-control w-75"
                                        id="formGroupExampleInput"
                                        value={surveysListDetails[surveyIndex]?.createdByUser?.name}
                                      />
                                      <InputGroupText>
                                        <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }}
                                          // onClick={e => navigate('/user', { state: { id: surveysListDetails[surveyIndex]?.createdBy, users: [], emptyFilterUsers: false } })} 
                                          onClick={e => {
                                            setFilterComponent(true);
                                            setFilterComponentState({
                                              id: surveysListDetails[surveyIndex]?.createdBy,
                                              users: [],
                                              emptyFilterUsers: false
                                            });
                                          }}
                                        ></i>
                                      </InputGroupText>
                                    </InputGroup>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label for="validationCustom04" className="form-label">
                                      Paid
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      placeholder={`${surveysListDetails[surveyIndex]?.totalPayment} €`}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="row">
                                      <div className="col-4 d-flex align-items-center">
                                        {
                                          surveysListDetails[surveyIndex]?.surveyAppSelection[0] ?
                                            <React.Fragment>
                                              <input
                                                type="text"
                                                className="form-control w-50"
                                                id="formGroupExampleInput"
                                                value={surveyAppSelectionCount}
                                                disabled
                                              />
                                              <i
                                                className="fa fa-pencil ms-3"
                                                style={{ cursor: "pointer" }}
                                                onClick={e => setSurveyAppSelectionModal(true)}
                                              ></i>
                                            </React.Fragment> :
                                            <React.Fragment>
                                              No Target Selection
                                            </React.Fragment>
                                        }
                                      </div>
                                      <div className="col-4  d-flex justify-content-start align-items-center">
                                        <InputGroup>
                                          <input
                                            type="number"
                                            className="form-control"
                                            id="formGroupExampleInput"
                                            disabled
                                            value={surveysListDetails[surveyIndex]?.users?.length}
                                          />
                                          <InputGroupText>
                                            <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }} onClick={e => handleNavigateToAttemptedUsersPage()} ></i>
                                          </InputGroupText>
                                        </InputGroup>
                                      </div>
                                      <div className="col-4 d-flex justify-content-end align-items-center">
                                        <Button className="ms-3 mt-3 mb-4 question-toggle-next" onClick={e => handleNavigateToResultPage()}>
                                          {" "}
                                          Web Page
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="row">
                                      <div className="col-6">
                                        <Label>Prize</Label>
                                        {
                                          surveysListDetails[surveyIndex]?.prize && surveysListDetails[surveyIndex]?.prize?.length > 0 ?
                                            <React.Fragment>
                                              <div className="col-12">
                                                <div style={{ maxHeight: "175px", position: "relative" }} className="">
                                                  <img
                                                    src={selectPrizeImg ? URL.createObjectURL(selectPrizeImg) : `${awsBaseUrl}${surveysListDetails[surveyIndex]?.prize[0]?.image}`}
                                                    style={{ objectFit: "cover", maxHeight: "150px", minHeight: "150px" }}
                                                    className="w-100"
                                                    alt=""
                                                    srcset=""
                                                  />
                                                  <i className="fa-solid fa-pen-to-square" style={{ position: "absolute", bottom: "5px", right: "10px", filter: "drop-shadow(3px 3px 10px #000)", cursor: "pointer", color: "#fff" }} onClick={e => {
                                                    checkSurveyAttendOrStopped() &&
                                                      clickChangePrizeImage();
                                                  }} ></i>
                                                  <input type="file" accept="image/*" style={{ display: "none" }} ref={inputFileRefPrize} onChange={e => onFileChangePrize(e)} />
                                                </div>
                                              </div>
                                              <div className="col-12 mt-3">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="formGroupExampleInput"
                                                  value={surveysListDetails[surveyIndex]?.prize[0]?.description}
                                                />
                                              </div>
                                              <div className="row">
                                                <div className="col-4 mt-3">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    value={surveysListDetails[surveyIndex]?.prize[0]?.numberOfPrizes}
                                                  >
                                                    <option selected disabled value="">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="col-8 mt-4 d-flex justify-content-end">
                                                  <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }} onClick={e => {
                                                    checkSurveyAttendOrStopped() &&
                                                      setEditSurveyPrizeModal(true);
                                                  }}></i>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                              <h5 className="text-center text-muted">No Prize Data</h5>
                                            </React.Fragment>
                                        }
                                      </div>
                                      <div className="col-6">
                                        <Label>Sponsor</Label>
                                        {
                                          surveysListDetails[surveyIndex]?.sponsor && surveysListDetails[surveyIndex]?.sponsor?.length > 0 ?
                                            <React.Fragment>
                                              <div className="col-12">
                                                <div style={{ maxHeight: "175px", position: "relative" }} className="">
                                                  <img
                                                    src={selectSponsorImg ? URL.createObjectURL(selectSponsorImg) : `${awsBaseUrl}${surveysListDetails[surveyIndex]?.sponsor[sponsorIndex]?.image}`}
                                                    style={{ objectFit: "cover", maxHeight: "150px", minHeight: "150px" }}
                                                    className="w-100"
                                                    alt=""
                                                    srcset=""
                                                  />
                                                  <i className="fa-solid fa-pen-to-square" style={{ position: "absolute", bottom: "5px", right: "10px", filter: "drop-shadow(3px 3px 10px #000)", cursor: "pointer", color: "#fff" }} onClick={e => {
                                                    checkSurveyAttendOrStopped() &&
                                                      clickChangeSponsorImage();
                                                  }} ></i>
                                                  <input type="file" accept="image/*" style={{ display: "none" }} ref={inputFileRefSponsor} onChange={e => onFileChangeSponsor(e)} />
                                                </div>
                                              </div>
                                              <div className="col-12 mt-3">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="formGroupExampleInput"
                                                  value={surveysListDetails[surveyIndex]?.sponsor[sponsorIndex]?.name}
                                                />
                                              </div>
                                              <div className="col-12 mt-3">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="formGroupExampleInput"
                                                  value={surveysListDetails[surveyIndex]?.sponsor[sponsorIndex]?.link}
                                                />
                                              </div>
                                              <div className="col-12 mt-5">
                                                <div className="row">
                                                  <div className="col-6 d-flex align-items-center">
                                                    {
                                                      surveysListDetails[surveyIndex]?.sponsor?.length > 0 && sponsorIndex !== 0 &&
                                                      <i
                                                        className="fa fa-chevron-left"
                                                        aria-hidden="true"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={e => handleClickPreviousSponsor()}
                                                      ></i>
                                                    }
                                                    <input
                                                      type="text"
                                                      className="form-control mx-3"
                                                      style={{ width: "70px" }}
                                                      disabled
                                                      id="formGroupExampleInput"
                                                      value={`${sponsorIndex + 1} of ${surveysListDetails[surveyIndex]?.sponsor?.length}`}
                                                    />
                                                    {
                                                      surveysListDetails[surveyIndex]?.sponsor?.length >= 1 && sponsorIndex !== surveysListDetails[surveyIndex]?.sponsor?.length - 1 &&
                                                      <i
                                                        className="fa fa-chevron-right"
                                                        aria-hidden="true"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={e => handleClickNextSponsor()}
                                                      ></i>
                                                    }
                                                  </div>
                                                  <div className="col-6 d-flex justify-content-end align-items-center">
                                                    <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer", marginRight: "15px" }} onClick={e => {
                                                      checkSurveyAttendOrStopped() &&
                                                        setAddSponsorModal(true);
                                                    }}></i>
                                                    <i className="fa-solid fa-pen-to-square" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                                      checkSurveyAttendOrStopped() &&
                                                        setEditSurveySposnorModal(true);
                                                    }}></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment> :
                                            <React.Fragment>
                                              <h5 className="text-muted text-center">No Sponsor Data</h5>
                                              <div className="col-12 d-flex justify-content-end align-items-end">
                                                <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                                  checkSurveyAttendOrStopped() &&
                                                    setAddSponsorModal(true);
                                                }}></i>
                                              </div>
                                            </React.Fragment>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="row m-auto">
                              <div className="col-12 my-3">
                                <h5 style={{ display: "inline", cursor: "pointer" }} className={`${questionState === "questions" ? "font-weight-bold" : "text-muted"}`} onClick={e => setQuestionState("questions")}>Questions</h5>
                                <h5 style={{ display: "inline", marginLeft: "10px", cursor: "pointer" }} className={`${questionState === "preliminaries" ? "font-weight-bold" : "text-muted"}`} onClick={e => setQuestionState("preliminaries")}>Preliminaries</h5>
                              </div>
                              {
                                // if question
                                questionState === "questions" ?
                                  <React.Fragment>
                                    <div className="col-12 mt-3">
                                      <select className="form-select" id="validationCustom04" value={surveysListDetails[surveyIndex]?.questions[questionIndex]?.questionType}>
                                        <option value="" selected disabled>Select</option>
                                        {
                                          questionType && questionType?.length > 0 && questionType?.map(q => (
                                            <option key={q?._id} value={q?._id}>{q?.type}</option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <InputGroup>
                                        <InputGroupText>
                                          <p>{`Q${questionIndex + 1}`}</p>
                                        </InputGroupText>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          value={surveysListDetails[surveyIndex]?.questions[questionIndex]?.title}
                                        />
                                      </InputGroup>
                                    </div>
                                    {
                                      surveysListDetails[surveyIndex]?.questions[questionIndex]?.options && surveysListDetails[surveyIndex]?.questions[questionIndex]?.options?.length > 0 && surveysListDetails[surveyIndex]?.questions[questionIndex]?.options?.map((o, i) => (
                                        <React.Fragment key={i}>
                                          <div className="col-10 mt-3">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="formGroupExampleInput"
                                              value={o}
                                            />
                                          </div>
                                          <div className="col-2 mt-3">
                                            <i className="fa-solid fa-file fa-2x" style={{ cursor: surveysListDetails[surveyIndex]?.questions[questionIndex]?.emails[i] ? "pointer" : "default", color: surveysListDetails[surveyIndex]?.questions[questionIndex]?.emails[i] ? "#212121" : "#757E94" }} onClick={e => {
                                              optionHtmlToggle();
                                              setUuid(surveysListDetails[surveyIndex]?.questions[questionIndex]?.urls[i]);
                                            }}></i>
                                          </div>
                                        </React.Fragment>
                                      ))
                                    }
                                    <div className="col-12 mt-3">
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="comments"
                                              disabled
                                              checked={surveysListDetails[surveyIndex]?.questions[questionIndex]?.comment}
                                            />
                                            <label className="form-check-label" for="comments">
                                              Comments
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                          <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                            checkSurveyAttendOrStopped() &&
                                              setAddOptionModal(true);
                                          }} ></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <hr />
                                    </div>
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col-6 d-flex align-items-center">
                                          {
                                            surveysListDetails[surveyIndex]?.questions?.length > 0 && questionIndex !== 0 &&
                                            <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleClickPreviousQuestion()}></i>
                                          }
                                          <input
                                            type="text"
                                            className="form-control w-50 mx-3"
                                            id="formGroupExampleInput"
                                            disabled
                                            value={`${questionIndex + 1} of ${surveysListDetails[surveyIndex]?.questions?.length}`}
                                          />
                                          {
                                            surveysListDetails[surveyIndex]?.questions?.length >= 1 && questionIndex !== surveysListDetails[surveyIndex]?.questions?.length - 1 &&
                                            <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleClickNextQuestion()}></i>
                                          }
                                        </div>
                                        <div className="col-6 d-flex justify-content-end align-items-center">
                                          <i className="fa fa-trash" aria-hidden="true" style={{ cursor: "pointer", marginRight: "15px" }} onClick={e => {
                                            if (checkSurveyAttendOrStopped()) {
                                              setDeleteQuestionId(surveysListDetails[surveyIndex]?.questions[questionIndex]?._id);
                                              setDeleteSurveyQuestionModal(true);
                                            }
                                          }} ></i>
                                          <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                            checkSurveyAttendOrStopped() &&
                                              setAddQuestionModal(true);
                                          }} ></i>
                                          <i className="fa-solid fa-pen-to-square ms-3" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                            checkSurveyAttendOrStopped() &&
                                              setEditQuestion(true);
                                          }}></i>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment> :
                                  // if preliminaries
                                  <React.Fragment>
                                    {
                                      surveysListDetails[surveyIndex]?.preliminary &&
                                      <React.Fragment>
                                        <div className="col-12 mt-3">
                                          <select className="form-select" id="validationCustom04" value={surveysListDetails[surveyIndex]?.preliminaryQuestionType}>
                                            <option value={null} selected disabled>Select</option>
                                            <option value="direct elimination">Direct Elimination</option>
                                            <option value="point system">Point System</option>
                                          </select>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <InputGroup>
                                            <InputGroupText>
                                              <p>{`Q${preliminaryIndex + 1}`}</p>
                                            </InputGroupText>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="formGroupExampleInput"
                                              value={surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.question}
                                            />
                                          </InputGroup>
                                        </div>
                                        {
                                          surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.options && surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.options?.length > 0 && surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.options?.map((o, i) => (
                                            <div className="col-12 mt-3" key={i}>
                                              <InputGroup>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="formGroupExampleInput"
                                                  value={o}
                                                />
                                                <InputGroupText>
                                                  {/* if preliminary question type is direct elimination */}
                                                  {
                                                    surveysListDetails[surveyIndex]?.preliminaryQuestionType === "direct elimination" &&
                                                    <React.Fragment>
                                                      {
                                                        surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.correctAnswer === o ?
                                                          <i className="fa-regular fa-circle-check text-success"></i> :
                                                          <i className="fa-regular fa-circle-xmark text-danger"></i>
                                                      }
                                                    </React.Fragment>
                                                  }
                                                  {/* if preliminary question type is point system */}
                                                  {
                                                    surveysListDetails[surveyIndex]?.preliminaryQuestionType === "point system" &&
                                                    <React.Fragment>
                                                      {surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.points[i]}
                                                    </React.Fragment>
                                                  }
                                                </InputGroupText>
                                              </InputGroup>
                                            </div>
                                          ))
                                        }
                                        <div className="col-12 mt-3">
                                          <div className="row">
                                            <div className="col-6">
                                              {
                                                surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.maxTime &&
                                                <p>Max Time: {surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]?.maxTime}</p>
                                              }
                                              {
                                                surveysListDetails[surveyIndex]?.preliminaryQuestionType === "point system" &&
                                                <p>Required Points: {surveysListDetails[surveyIndex]?.requiredPoints
                                                }</p>
                                              }
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                              <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                                if (surveysListDetails[surveyIndex]?.users?.length > 0) {
                                                  toast("Survey are attended by user, you can't Add Survey Preliminary Option");
                                                  return;
                                                }
                                                setAddPreliminaryOptionModal(true);
                                              }}></i>
                                            </div>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    }
                                    <div className="col-12">
                                      <hr />
                                    </div>
                                    <div className="col-12">
                                      <div className="row">
                                        {
                                          surveysListDetails[surveyIndex]?.preliminary &&
                                          <React.Fragment>
                                            <div className="col-6 d-flex align-items-center">
                                              {
                                                surveysListDetails[surveyIndex]?.preliminaryQuestions?.length > 0 && preliminaryIndex !== 0 &&
                                                <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleClickPreviousPreliminaryQuestion()}></i>
                                              }
                                              <input
                                                type="text"
                                                className="form-control w-50 mx-3"
                                                id="formGroupExampleInput"
                                                disabled
                                                value={`${preliminaryIndex + 1} of ${surveysListDetails[surveyIndex]?.preliminaryQuestions?.length}`}
                                              />
                                              {
                                                surveysListDetails[surveyIndex]?.preliminaryQuestions?.length >= 1 && preliminaryIndex !== surveysListDetails[surveyIndex]?.preliminaryQuestions?.length - 1 &&
                                                <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleClickNextPreliminaryQuestion()}></i>
                                              }
                                            </div>
                                            <div className="col-6 d-flex justify-content-end align-items-center float-right">
                                              <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                                if (surveysListDetails[surveyIndex]?.users?.length > 0) {
                                                  toast("Survey are attended by user, you can't Add Survey Preliminary");
                                                  return;
                                                }
                                                setAddPreliminaryModal(true);
                                              }}></i>
                                              <i className="fa-solid fa-pen-to-square ms-3" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                                checkSurveyAttendOrStopped() &&
                                                  setEditPreliminaryModal(true);
                                              }}></i>
                                            </div>
                                          </React.Fragment>
                                        }
                                        {
                                          !surveysListDetails[surveyIndex]?.preliminary &&
                                          <div style={{ width: "100%", display: "flex", justifyContent: 'end' }}>
                                            <i className="fa fa-plus" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                              if (surveysListDetails[surveyIndex]?.users?.length > 0) {
                                                toast("Survey are attended by user, you can't Add Survey Preliminary");
                                                return;
                                              }
                                              setAddPreliminaryModal(true);
                                            }}></i>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </React.Fragment>
                              }
                            </div>
                          </div>
                          <div
                            className="col-12"
                            style={{ paddingLeft: "32px", paddingRight: "2rem" }}
                          >
                            <hr className="my-5" />
                          </div>
                          <div className="col-12" style={{ paddingLeft: "32px" }}>
                            <div className="row">
                              <div className="col-3 d-flex align-items-center">
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handlePreviousSurveyClick()}></i>
                                <input
                                  type="text"
                                  className="form-control w-50 mx-3"
                                  id="formGroupExampleInput"
                                  disabled
                                  value={`${surveyIndex + 1} of ${surveysListDetails && surveysListDetails?.length}`}
                                />
                                <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNextSurveyClick()}></i>
                              </div>
                              <div className="col-9 d-flex justify-content-start align-items-center">
                                <i className="fa fa-user fa-2x" aria-hidden="true" style={{ cursor: "pointer" }}
                                  onClick={e => handleNavigateListOfSurveyOrganizers()}
                                ></i>
                                <i className="fa fa-bullseye ms-5 fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleRedirectToTargetSelectionUsersByAllListSurveys()}></i>
                                <i className="fa fa-users ms-5 fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNavigateListOfSurveyAttendees()}></i>
                              </div>
                            </div>
                          </div>
                        </React.Fragment> :
                        <React.Fragment>
                          <div style={{ marginTop: "20px" }}>
                            <h3 className="text-muted text-center">No Data</h3>
                          </div>
                        </React.Fragment>
                    }
                  </div>
                  {/* confirm delete survey popup */}
                  <React.Fragment>
                    <Modal isOpen={deleteSurveyModal} toggle={deleteSurveyToggle} backdrop={"static"} size="sm" centered>
                      <ModalBody className='p-3'>
                        <h4 className='text-center'>Are you sure?</h4>
                      </ModalBody>
                      <ModalFooter className='m-auto'>
                        <Button color="danger" onClick={e => {
                          handleDeleteSurvey();
                        }}>Delete</Button>{' '}
                        <Button color="secondary" onClick={e => {
                          deleteSurveyToggle();
                        }}>Cancel</Button>
                      </ModalFooter>
                    </Modal>
                  </React.Fragment>

                  {/* confirm stop survey popup */}
                  <React.Fragment>
                    <Modal isOpen={stopSurveyModal} toggle={stopSurveyToggle} backdrop={"static"} size="sm" centered>
                      <ModalBody className='p-3'>
                        <h4 className='text-center'>Are you sure?</h4>
                      </ModalBody>
                      <ModalFooter className='m-auto'>
                        <Button color="danger" onClick={e => {
                          handleStopSurvey();
                        }}>Stop</Button>{' '}
                        <Button color="secondary" onClick={e => {
                          stopSurveyToggle();
                        }}>Cancel</Button>
                      </ModalFooter>
                    </Modal>
                  </React.Fragment>


                  <EditSurveySettingModal surveySettingModal={surveySettingModal} surveySettingToggle={surveySettingToggle} surveysListDetails={surveysListDetails} surveyIndex={surveyIndex} surveyActions={surveyActions} />

                  <AddSponsorModal addSponsorModal={addSponsorModal} addSponsorToggle={addSponsorToggle} surveyActions={surveyActions} surveyId={surveysListDetails[surveyIndex]?._id} />

                  <AddQuestionModal addQuestionModal={addQuestionModal} addQuestionToggle={addQuestionToggle} surveyActions={surveyActions} surveyId={surveysListDetails[surveyIndex]?._id} />

                  <EditQuestionModal editQuestion={editQuestion} editQuestionToggle={editQuestionToggle} surveyActions={surveyActions} surveyQuestion={surveysListDetails[surveyIndex]?.questions[questionIndex]} />

                  <AddOptionModal addOptionModal={addOptionModal} addOptionToggle={addOptionToggle} surveyActions={surveyActions} surveyQuestion={surveysListDetails[surveyIndex]?.questions[questionIndex]} />

                  <AddPreliminary addPreliminaryModal={addPreliminaryModal} addPreliminaryToggle={addPreliminaryToggle} surveyActions={surveyActions} surveyDetails={surveysListDetails[surveyIndex]} surveyIndex={surveyIndex} preliminaryIndex={preliminaryIndex} />

                  <EditPreliminary editPreliminaryModal={editPreliminaryModal} editPreliminaryToggle={editPreliminaryToggle} surveyActions={surveyActions} surveyDetails={surveysListDetails[surveyIndex]} surveyIndex={surveyIndex} preliminaryIndex={preliminaryIndex} questionData={surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]} />

                  <AddPreliminaryOptionModal addPreliminaryOptionModal={addPreliminaryOptionModal} addPreliminaryOptionToggle={addPreliminaryOptionToggle} surveyActions={surveyActions} surveyDetails={surveysListDetails[surveyIndex]} questionData={surveysListDetails[surveyIndex]?.preliminaryQuestions[preliminaryIndex]} />

                  <AppSelectionModal surveyAppSelectionModal={surveyAppSelectionModal} surveyAppSelectionToggle={surveyAppSelectionToggle} surveyAppSelectionCount=
                    {surveyAppSelectionCount} appSelectionData={surveysListDetails[surveyIndex]?.surveyAppSelection[0]} surveyId={surveysListDetails[surveyIndex]?._id} surveyActions={surveyActions} dropdownActions={dropdownActions} surveysListDetails={surveysListDetails} surveyIndex={surveyIndex} />

                  {
                    surveysListDetails[surveyIndex]?.prize && surveysListDetails[surveyIndex]?.prize?.length > 0 &&
                    <EditSurveyPrize editSurveyPrizeModal={editSurveyPrizeModal} editSurveyPrizeToggle={editSurveyPrizeToggle} surveyActions={surveyActions} prizeData={surveysListDetails[surveyIndex]?.prize[0]} />
                  }

                  <EditSurveySponsor editSurveySponsorModal={editSurveySponsorModal} editSurveySponsorToggle={editSurveySponsorToggle} sponsorData={surveysListDetails[surveyIndex]?.sponsor[sponsorIndex]} surveyActions={surveyActions} surveyIndex={surveyIndex} />

                  <ConfirmDeleteQuestion deleteSurveyQuestionModal={deleteSurveyQuestionModal} setDeleteQuestionId={setDeleteQuestionId} deleteSurveyQuestionToggle={deleteSurveyQuestionToggle} deleteQuestionId={deleteQuestionId} surveyActions={surveyActions} setQuestionIndex={setQuestionIndex} />

                  {
                    uuid &&
                    <QuestionOptionDocModal optionHtmlModal={optionHtmlModal} optionHtmlToggle={optionHtmlToggle} uuid={uuid} />
                  }

                </React.Fragment>
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Surveys;
