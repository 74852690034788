import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const SOCIAL_LOGIN_USER_REQUEST = 'SOCIAL_LOGIN_USER_REQUEST';
export const SOCIAL_LOGIN_USER_SUCCESS = 'SOCIAL_LOGIN_USER_SUCCESS';
export const SOCIAL_LOGIN_USER_FAILURE = 'SOCIAL_LOGIN_USER_FAILURE';

export const GET_LOGIN_USER_REQUEST = 'GET_LOGIN_USER_REQUEST';
export const GET_LOGIN_USER_SUCCESS = 'GET_LOGIN_USER_SUCCESS';
export const GET_LOGIN_USER_FAILURE = 'GET_LOGIN_USER_FAILURE';

export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS_REQUEST';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE = 'UPDATE_USER_DETAILS_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

// new design
export const GET_USER_LIST_DETAILS_REQUEST = 'GET_USER_LIST_DETAILS_REQUEST';
export const GET_USER_LIST_DETAILS_SUCCESS = 'GET_USER_LIST_DETAILS_SUCCESS';
export const GET_USER_LIST_DETAILS_FAILURE = 'GET_USER_LIST_DETAILS_FAILURE';

export const UPDATE_USER_DETAIL_REQUEST = 'UPDATE_USER_DETAIL_REQUEST';
export const UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_FAILURE = 'UPDATE_USER_DETAIL_FAILURE';

export const SEND_EMAIL_TO_USERS_REQUEST = 'SEND_EMAIL_TO_USERS_REQUEST';
export const SEND_EMAIL_TO_USERS_SUCCESS = 'SEND_EMAIL_TO_USERS_SUCCESS';
export const SEND_EMAIL_TO_USERS_FAILURE = 'SEND_EMAIL_TO_USERS_FAILURE';

export const SEND_NOTIFICATION_TO_USERS_REQUEST = 'SEND_NOTIFICATION_TO_USERS_REQUEST';
export const SEND_NOTIFICATION_TO_USERS_SUCCESS = 'SEND_NOTIFICATION_TO_USERS_SUCCESS';
export const SEND_NOTIFICATION_TO_USERS_FAILURE = 'SEND_NOTIFICATION_TO_USERS_FAILURE';

export const ACTIVE_INACTIVE_USER_REQUEST = 'ACTIVE_INACTIVE_USER_REQUEST';
export const ACTIVE_INACTIVE_USER_SUCCESS = 'ACTIVE_INACTIVE_USER_SUCCESS';
export const ACTIVE_INACTIVE_USER_FAILURE = 'ACTIVE_INACTIVE_USER_FAILURE';

export const loginUser = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/login/admin/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
            navigate('/');
            toast(response?.data?.message);
        } catch (error) {
            console.error('error', error);
            dispatch({ type: LOGIN_USER_FAILURE, payload: error });
            toast(error?.response?.data?.message);
        }
    }
};

export const socialLoginUser = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SOCIAL_LOGIN_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/web/social/login`;
            const response = await axios.post(url, data);
            dispatch({ type: SOCIAL_LOGIN_USER_SUCCESS, payload: response.data });
            navigate('/');
            toast(response?.data?.message);
        } catch (error) {
            console.error('error', error);
            dispatch({ type: SOCIAL_LOGIN_USER_FAILURE, payload: error });
            toast(error?.response?.data?.message);
        }
    }
};

export const getLoginUser = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_LOGIN_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/details/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_LOGIN_USER_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error?.response?.data', error?.response?.data?.status)
            if (error?.response?.data?.status === 401) {
                localStorage.removeItem('Authorization');
                navigate('/login');
            }
            console.error('error', error);
            dispatch({ type: GET_LOGIN_USER_FAILURE, payload: error });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateUserDetails = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_USER_DETAILS_REQUEST });
            // upload image in aws bucket
            if (user && user.image && user.image.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/profile/pre-signed-url/v1`, {
                    sFileName: user.image.file.name,
                    sContentType: user.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const url = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(url, user.image.file, { headers: { "Content-Type": user.image.file.type } });
                user = {
                    ...user,
                    image: sImage
                }
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/update/user/details/v1`;
                const response = await axios.put(apiUrl, user, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: response.data });
                toast(response.data.message);
            } else {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/update/user/details/v1`;
                const response = await axios.put(apiUrl, user, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: response.data });
                toast(response.data.message);
            }

        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_USER_DETAILS_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

export const forgotPassword = (resettoken, password, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: FORGOT_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/setpassword/v1/${resettoken}`;
            const res = await axios.post(url, { password });
            dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data });
            navigate("/success");
            toast("Your password updated successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: FORGOT_PASSWORD_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

// new design changes
export const getUserListDetails = (search, id, users, usersFilter) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_LIST_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/users/details/v1?search=${search}&id=${id}`;
            const res = await axios.post(url, { users, ...usersFilter }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_USER_LIST_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_LIST_DETAILS_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateUserDetail = (id, data, editUserProfileToggle, clearState) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_UPDATE_USER_STATUS" });
            dispatch({ type: UPDATE_USER_DETAIL_REQUEST });


            // upload image in aws bucket
            if (data && data.image && data.image.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/profile/pre-signed-url/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });
                const url = `${process.env.REACT_APP_BASE_URL}/admin/update/user/details/${id}/v1`;
                const res = await axios.put(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: UPDATE_USER_DETAIL_SUCCESS, payload: res.data });
                editUserProfileToggle();
                clearState();
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/admin/update/user/details/${id}/v1`;
                const res = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: UPDATE_USER_DETAIL_SUCCESS, payload: res.data });
                editUserProfileToggle();
                clearState();
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_USER_DETAIL_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

export const sendEmailToUsers = (data, toggle, clearState) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_TO_USERS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/send/email/users/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: SEND_EMAIL_TO_USERS_SUCCESS, payload: res.data });
            toggle();
            clearState();
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_EMAIL_TO_USERS_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

export const sendNotificationToUsers = (data, toggle, clearState) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_NOTIFICATION_TO_USERS_REQUEST });


            // upload image in aws bucket
            if (data && data.image && data.image.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const url = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(url, data.image.file, { headers: { "Content-Type": data.image.file.type } });
                data = {
                    ...data,
                    image: `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${sImage}`
                }
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/users/v1`;
                const res = await axios.post(apiUrl, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_USERS_SUCCESS, payload: res.data });
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/users/v1`;
                const res = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_USERS_SUCCESS, payload: res.data });
            }
            toggle();
            clearState();
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_NOTIFICATION_TO_USERS_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};

export const activeInactiveUser = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_UPDATE_USER_STATUS" });
            dispatch({ type: ACTIVE_INACTIVE_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/change/user/status/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: ACTIVE_INACTIVE_USER_SUCCESS, payload: res.data });
            toast("User status changed");
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIVE_INACTIVE_USER_FAILURE });
            toast(error?.response?.data?.message);
        }
    }
};