import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const VERIFY_GROUP_REQUEST = 'VERIFY_GROUP_REQUEST';
export const VERIFY_GROUP_SUCCESS = 'VERIFY_GROUP_SUCCESS';
export const VERIFY_GROUP_FAILURE = 'VERIFY_GROUP_FAILURE';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

// new design changes api's
export const GET_GROUP_LIST_DETAILS_REQUEST = 'GET_GROUP_LIST_DETAILS_REQUEST';
export const GET_GROUP_LIST_DETAILS_SUCCESS = 'GET_GROUP_LIST_DETAILS_SUCCESS';
export const GET_GROUP_LIST_DETAILS_FAILURE = 'GET_GROUP_LIST_DETAILS_FAILURE';

export const groupVerify = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GROUP_STATE_CHANGE" });
            dispatch({ type: VERIFY_GROUP_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/group/verify/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: VERIFY_GROUP_SUCCESS, payload: res.data });
            toast(data.verify === "A" ? "Group Verified Successfully.." : "Group Verification has been Rejected");
        } catch (error) {
            console.error(error);
            dispatch({ type: VERIFY_GROUP_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateGroupDetails = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GROUP_STATE_CHANGE" });
            dispatch({ type: UPDATE_GROUP_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/group/update/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_GROUP_SUCCESS, payload: res.data });
            toast("Group Details Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_GROUP_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteGroup = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GROUP_STATE_CHANGE" });
            dispatch({ type: DELETE_GROUP_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/group/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_GROUP_SUCCESS, payload: res.data });
            toast("Group Deleted Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_GROUP_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

// new design changes api's
export const getGroupListDetails = (search, status, id, user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_GROUP_LIST_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/group/list/details/v1?search=${search}&status=${status}&id=${id}&user=${user}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_GROUP_LIST_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_GROUP_LIST_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};