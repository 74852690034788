import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, InputGroup, Input, Button, Card, CardBody } from 'reactstrap';
import * as htmlDocActions from "../../redux/optionHtmlDoc/htmlDoc.actions";
import { awsBaseUrl } from '../../helper';
import moment from 'moment';

const Comment = ({ commentModal, commentToggle, doc, commentList, user }) => {

  const [commentText, setCommentText] = useState('');
  const [replyCommentText, setReplyCommentText] = useState([]);
  const dispatch = useDispatch();

  const handleReplySubmit = (e, i, id) => {
    e.preventDefault();
    dispatch(htmlDocActions.replyCommentHtmlDoc({ htmlDocId: doc?._id, commentId: id, comment: replyCommentText[i] }, setReplyCommentText));
  };

  const handleCommentLike = (id) => {
    dispatch(htmlDocActions.likeCommentHtmlDoc({ htmlDocId: doc?._id, commentId: id }));
  };

  const handleCommentUnlike = (id) => {
    dispatch(htmlDocActions.unlikeCommentHtmlDoc({ htmlDocId: doc?._id, commentId: id }));
  };

  const handleReplyLike = (commentId, replyId) => {
    const data = { htmlDocId: doc?._id, commentId, replyCommentId: replyId };
    dispatch(htmlDocActions.likeReplyCommentHtmlDoc(data));
  };

  const handleReplyUnlike = (commentId, replyId) => {
    const data = { htmlDocId: doc?._id, commentId, replyCommentId: replyId };
    dispatch(htmlDocActions.unlikeReplyCommentHtmlDoc(data));
  };

  const handleCreateComment = () => {
    dispatch(htmlDocActions.commentHtmlDoc({ comment: commentText, htmlDocId: doc?._id }, setCommentText));
  };

  const handleDeleteComment = (id) => {
    dispatch(htmlDocActions.deleteCommentHtmlDoc(id));
  };

  const handleDeleteReplyComment = (id) => {
    dispatch(htmlDocActions.deleteReplyCommentHtmlDoc(id));
  };

  return (
    <React.Fragment>
      <Modal isOpen={commentModal} toggle={commentToggle} backdrop={"static"} className="modal-md fullscreen-modal" centered>
        <ModalHeader>
          <span aria-hidden="true" style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }} onClick={e => commentToggle()}>x</span>
          <h4>Comments</h4>
        </ModalHeader>
        <ModalBody style={{ overflowY: "scroll" }}>
          {
            commentList && commentList?.length > 0 && commentList.map((comment, i) => {
              return (
                <div className="comment-card" key={comment?._id}>
                  <img
                    className="avatar"
                    src={`${awsBaseUrl}${comment?.createdByUser?.image}`}
                    alt="User Avatar"
                  />
                  <div className="comment-content">
                    {
                      comment?.createdBy === user?._id &&
                      <i className="fa-solid fa-trash text-danger" style={{ float: "right", cursor: "pointer" }} onClick={e => handleDeleteComment(comment?._id)}></i>
                    }
                    <h3 className="comment-author">{comment?.createdByUser?.name} - <span className='text-muted' style={{ fontSize: "11px" }}>{moment(comment?.createdAt).fromNow()}</span></h3>
                    <p className="comment-text">{comment?.comment}</p>
                    <div className="comment-actions">
                      {/* like */}
                      <i className={`${comment?.isLikeComment === 0 ? 'fa-regular fa-thumbs-up' : 'fa-solid fa-thumbs-up'}`} style={{ cursor: "pointer" }} onClick={e => handleCommentLike(comment._id)}></i> {comment?.likes?.length}
                      {/* dislike */}
                      <i className={`${comment?.isDisLikeComment === 0 ? 'fa-regular fa-thumbs-down' : 'fa-solid fa-thumbs-down'}`} style={{ cursor: "pointer", marginLeft: "15px" }} onClick={e => handleCommentUnlike(comment._id)}></i> {comment?.disLikes?.length}
                    </div>
                    <form className="reply-form">
                      <textarea
                        className="reply-textarea"
                        placeholder="Type your reply"
                        rows="2"
                        value={replyCommentText[i]}
                        onChange={(e) => {
                          replyCommentText[i] = e.target.value;
                          setReplyCommentText(replyCommentText);
                        }}
                      />
                      <button className="reply-submit-button" onClick={e => handleReplySubmit(e, i, comment?._id)}>
                        Submit
                      </button>
                    </form>
                    {comment?.htmlDocCommentReplies.length > 0 && (
                      <div className="replies">
                        {comment?.htmlDocCommentReplies.map((reply) => (
                          <div key={reply?._id} style={{ display: 'flex', marginTop: "10px" }}>
                            <img
                              className="avatar"
                              src={`${awsBaseUrl}${reply?.createdByUser?.image}`}
                              alt="User Avatar"
                            />
                            <div style={{ width: "100%" }}>
                              {
                                reply?.createdBy === user._id &&
                                <i className="fa-solid fa-trash text-danger" style={{ float: "right", cursor: "pointer" }} onClick={e => handleDeleteReplyComment(reply._id)}></i>
                              }
                              <h3 className="comment-author">{reply?.createdByUser?.name} - <span className='text-muted' style={{ fontSize: "11px" }}>{moment(reply?.createdAt).fromNow()}</span></h3>
                              <p className="comment-text">{reply?.comment}</p>
                              <div className="comment-actions">
                                {/* like */}
                                <i className={`${reply?.isLikeComment === 0 ? 'fa-regular fa-thumbs-up' : 'fa-solid fa-thumbs-up'}`} style={{ cursor: "pointer" }} onClick={e => handleReplyLike(comment._id, reply._id)}></i>  {reply?.likes.length}
                                {/* dislike */}
                                <i className={`${reply?.isDisLikeComment === 0 ? 'fa-regular fa-thumbs-down' : 'fa-solid fa-thumbs-down'}`} style={{ cursor: "pointer", marginLeft: "15px" }} onClick={e => handleReplyUnlike(comment._id, reply._id)
                                }></i>  {reply?.disLikes.length}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            })
          }
        </ModalBody>
        <ModalFooter>
          <InputGroup>
            <Input type='text' placeholder="Type Comment" value={commentText} onChange={e => setCommentText(e.target.value)} />
            <Button color='light' addonType="append" disabled={commentText === "" ? true : false} onClick={e => handleCreateComment()}><i className='fa-solid fa-paper-plane' style={{ fontSize: "20px", color: "#8F2D56" }}></i></Button>
          </InputGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default Comment;