import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const AddSponsorModal = ({ addSponsorModal, addSponsorToggle, surveyActions, surveyId }) => {

  const [sponsor, setSponsor] = useState({
    surveyId: "",
    image: "",
    name: "",
    link: ""
  });
  const [selectedImage, setSelectedImage] = useState("");
  const dispatch = useDispatch();

  const handleAddSponsor = () => {
    // add sponsor
    const { image, link, name } = sponsor;
    if (!image || !link || !name) {
      toast("Please Fill Fields");
      return;
    }
    dispatch(surveyActions.adminAddSurveySponsor({ ...sponsor, surveyId }, addSponsorToggle, setSponsor, setSelectedImage));
  };

  const handleSelectImage = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    setSponsor({ ...sponsor, image: { file: e.target.files[0] } });
  };

  return (
    <React.Fragment>
      <Modal isOpen={addSponsorModal} toggle={addSponsorToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Add Survey Sponsor</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Row>
            {
              selectedImage &&
              <Col md="4" className="mb-3">
                <img src={selectedImage} style={{ width: "100%", height: "200px" }} />
              </Col>
            }
            <Col md="12">
              <FormGroup>
                <Input type="file" accept="image/*" onChange={e => handleSelectImage(e)} />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input type="text" placeholder="Sponsor name" value={sponsor.name} onChange={e => setSponsor({ ...sponsor, name: e.target.value })} />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input type="text" placeholder="Sponsor link" value={sponsor.link} onChange={e => setSponsor({ ...sponsor, link: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            addSponsorToggle();
            setSponsor({
              image: "",
              name: "",
              link: ""
            });
            setSelectedImage("");
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleAddSponsor()
          }}>Add</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default AddSponsorModal;