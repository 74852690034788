import { combineReducers } from "redux";
import * as userReducer from './user/user.reducer';
import * as dropdownReducer from './dropdown/dropdown.reducers';
import * as surveyReducers from './survey/survey.reducers';
import * as groupReducers from './group/group.reducers';
import * as settingReducers from "./setting/setting.reducer";
import * as paymentReducers from "./payment/payment.reducer";
import * as htmlDocReducers from "./optionHtmlDoc/htmlDoc.reducer";
import * as helpReducers from "./help/help.reducer";

export const rootReducer = combineReducers({
    [userReducer.userFeatureKey]: userReducer.reducer,
    [dropdownReducer.dropdownFeatureKey]: dropdownReducer.reducer,
    [surveyReducers.surveyFeatureKey]: surveyReducers.reducer,
    [groupReducers.groupFeatureKey]: groupReducers.reducer,
    [settingReducers.settingsFeatureKey]: settingReducers.reducer,
    [paymentReducers.paymentFeatureKey]: paymentReducers.reducer,
    [htmlDocReducers.htmlDocFeatureKey]: htmlDocReducers.reducer,
    [helpReducers.helpFeatureKey]: helpReducers.reducer
});