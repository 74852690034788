import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { awsBaseUrl } from "../../helper";

const EditSurveySponsor = ({ editSurveySponsorModal, editSurveySponsorToggle, surveyActions, sponsorData, surveyIndex }) => {

  const [surveySponsor, setSurveySponsor] = useState({
    surveyId: "",
    image: "",
    name: "",
    link: ""
  });
  const [tempImg, setTempImg] = useState("");
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setSurveySponsor({
      ...surveySponsor,
      surveyId: sponsorData?.surveyId,
      image: sponsorData?.image,
      name: sponsorData?.name,
      link: sponsorData?.link
    });
  }, [editSurveySponsorModal, sponsorData, surveyIndex]);

  const clickChangeIcon = () => {
    inputFileRef.current.click();
  };

  const onFileChange = (e) => {
    setTempImg(URL.createObjectURL(e.target.files[0]));
    setSurveySponsor({
      ...surveySponsor,
      image: {
        file: e.target.files[0]
      }
    });
  };

  const handleEditSurveySponsor = () => {
    dispatch(surveyActions.adminEditSurveySposnor(sponsorData?._id, surveySponsor, editSurveySponsorToggle));
  };

  return (
    <React.Fragment>
      <Modal isOpen={editSurveySponsorModal} toggle={editSurveySponsorToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Update Survey Prize</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Container>
            <Row>
              <Col md="3" className="mt-3 mb-3" style={{ position: "relative" }}>
                <img src={tempImg ? tempImg : `${awsBaseUrl}${surveySponsor?.image}`} style={{ height: "120px", width: "100%" }} />
                <i className="fa-solid fa-pen-to-square" style={{ position: "absolute", bottom: "5px", right: "17px", filter: "drop-shadow(3px 3px 10px #000)", cursor: "pointer", color: "#fff" }} onClick={e => clickChangeIcon()}></i>
                <input type="file" accept="image/*" style={{ display: "none" }} ref={inputFileRef} onChange={e => onFileChange(e)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text" value={surveySponsor.name} onChange={e => setSurveySponsor({ ...surveySponsor, name: e.target.value })} />
                </FormGroup>
                <FormGroup>
                  <Label>Link</Label>
                  <Input type="text" value={surveySponsor.link} onChange={e => setSurveySponsor({ ...surveySponsor, link: e.target.value })} />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            editSurveySponsorToggle();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleEditSurveySponsor()
          }}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default EditSurveySponsor;