import * as dropdownActions from './dropdown.actions';

export const dropdownFeatureKey = 'dropdownInfo';

const initialState = {
    loading: true,
    errorMsg: "",
    groupType: [],
    argumentType: [],
    civilStateType: [],
    educationType: [],
    isCreateGroupType: false,
    isCreateArgumentType: false,
    isCreateCivilStateType: false,
    isCreateEducationType: false,
    questionType: [],
    // isCreateQuestionType: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // create group type
        case dropdownActions.CREATE_GROUP_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.CREATE_GROUP_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateGroupType: true
            }

        case dropdownActions.CREATE_GROUP_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateGroupType: false
            }

        // get group type list
        case dropdownActions.GET_GROUP_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.GET_GROUP_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                groupType: payload.data
            }

        case dropdownActions.GET_GROUP_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update group type
        case dropdownActions.UPDATE_GROUP_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.UPDATE_GROUP_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateGroupType: true
            }

        case dropdownActions.UPDATE_GROUP_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateGroupType: false
            }

        // delete group type
        case dropdownActions.DELETE_GROUP_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.DELETE_GROUP_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateGroupType: true
            }

        case dropdownActions.DELETE_GROUP_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateGroupType: false
            }

        // // create question type
        // case dropdownActions.CREATE_QUESTION_TYPE_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case dropdownActions.CREATE_QUESTION_TYPE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         isCreateQuestionType: true
        //     }

        // case dropdownActions.CREATE_QUESTION_TYPE_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload,
        //         isCreateQuestionType: false
        //     }

        // // get question type list
        case dropdownActions.GET_QUESTION_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.GET_QUESTION_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                questionType: payload.data
            }

        case dropdownActions.GET_QUESTION_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // // update question type
        // case dropdownActions.UPDATE_QUESTION_TYPE_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case dropdownActions.UPDATE_QUESTION_TYPE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         isCreateQuestionType: true
        //     }

        // case dropdownActions.UPDATE_QUESTION_TYPE_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload,
        //         isCreateQuestionType: false
        //     }

        // // delete question type
        // case dropdownActions.DELETE_QUESTION_TYPE_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case dropdownActions.DELETE_QUESTION_TYPE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         isCreateQuestionType: true
        //     }

        // case dropdownActions.DELETE_QUESTION_TYPE_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload,
        //         isCreateQuestionType: false
        //     }


        // create argument type
        case dropdownActions.CREATE_ARGUMENT_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.CREATE_ARGUMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateArgumentType: true
            }

        case dropdownActions.CREATE_ARGUMENT_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateArgumentType: false
            }

        // get argument type list
        case dropdownActions.GET_ARGUMENT_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.GET_ARGUMENT_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                argumentType: payload.data
            }

        case dropdownActions.GET_ARGUMENT_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update argument type
        case dropdownActions.UPDATE_ARGUMENT_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.UPDATE_ARGUMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateArgumentType: true
            }

        case dropdownActions.UPDATE_ARGUMENT_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateArgumentType: false
            }

        // delete argument type
        case dropdownActions.DELETE_ARGUMENT_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.DELETE_ARGUMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateArgumentType: true
            }

        case dropdownActions.DELETE_ARGUMENT_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateArgumentType: false
            }


        // create civil state type
        case dropdownActions.CREATE_CIVIL_STATE_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.CREATE_CIVIL_STATE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateCivilStateType: true
            }

        case dropdownActions.CREATE_CIVIL_STATE_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateCivilStateType: false
            }

        // get civil state type list
        case dropdownActions.GET_CIVIL_STATE_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.GET_CIVIL_STATE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                civilStateType: payload.data
            }

        case dropdownActions.GET_CIVIL_STATE_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update civil state type
        case dropdownActions.UPDATE_CIVIL_STATE_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.UPDATE_CIVIL_STATE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateCivilStateType: true
            }

        case dropdownActions.UPDATE_CIVIL_STATE_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateCivilStateType: false
            }

        // delete civil state type
        case dropdownActions.DELETE_CIVIL_STATE_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.DELETE_CIVIL_STATE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateCivilStateType: true
            }

        case dropdownActions.DELETE_CIVIL_STATE_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateCivilStateType: false
            }


        // create education type
        case dropdownActions.CREATE_EDUCATION_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.CREATE_EDUCATION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateEducationType: true
            }

        case dropdownActions.CREATE_EDUCATION_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateEducationType: false
            }

        // get civil state type list
        case dropdownActions.GET_EDUCATION_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.GET_EDUCATION_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                educationType: payload.data
            }

        case dropdownActions.GET_EDUCATION_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update civil state type
        case dropdownActions.UPDATE_EDUCATION_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.UPDATE_EDUCATION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateEducationType: true
            }

        case dropdownActions.UPDATE_EDUCATION_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateEducationType: false
            }

        // delete civil state type
        case dropdownActions.DELETE_EDUCATION_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case dropdownActions.DELETE_EDUCATION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreateEducationType: true
            }

        case dropdownActions.DELETE_EDUCATION_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isCreateEducationType: false
            }


        // clear group type
        case 'CLEAR_CREATE_GROUP_TYPE':
            return {
                ...state,
                isCreateGroupType: false
            }

        // clear argument type
        case 'CLEAR_CREATE_ARGUMENT_TYPE':
            return {
                ...state,
                isCreateArgumentType: false
            }

        // clear civil state type
        case 'CLEAR_CREATE_CIVIL_STATE_TYPE':
            return {
                ...state,
                isCreateCivilStateType: false
            }

        // clear education type
        case 'CLEAR_CREATE_EDUCATION_TYPE':
            return {
                ...state,
                isCreateEducationType: false
            }

        // // clear question type
        // case 'CLEAR_CREATE_QUESTION_TYPE':
        //     return {
        //         ...state,
        //         isCreateQuestionType: false
        //     }

        default: return state;
    }
};