import * as surveyActions from "./survey.actions";

export const surveyFeatureKey = "surveyInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    surveysListDetails: [],
    result: [],
    questions: {},
    carachteristicsQuestion: [],
    countries: [],
    states: [],
    regions: [],
    provincias: [],
    comunes: [],
    cities: [],
    countriesUserFilter: [],
    statesUserFilter: [],
    regionsUserFilter: [],
    provinciasUserFilter: [],
    comunesUserFilter: [],
    citiesUserFilter: [],
    matchAnswers: [],
    surveyAppSelectionCount: null,
    isSurveyStatusChange: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        // survey questions
        case surveyActions.GET_SURVEY_QUESTIONS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_SURVEY_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: payload.data,
                carachteristicsQuestion: payload.carach
            }

        case surveyActions.GET_SURVEY_QUESTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // survey result
        case surveyActions.GET_SURVEY_RESULT_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_SURVEY_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                result: payload.data,
                matchAnswers: payload.sameAnswers
            }

        case surveyActions.GET_SURVEY_RESULT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get country list
        case surveyActions.GET_COUNTRY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countries: payload.data
            }

        case surveyActions.GET_COUNTRY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get state list
        case surveyActions.GET_STATE_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                states: payload.data
            }

        case surveyActions.GET_STATE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get city list
        case surveyActions.GET_CITY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                cities: payload.data
            }

        case surveyActions.GET_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get region list
        case surveyActions.GET_REGION_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_REGION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                regions: payload.data
            }

        case surveyActions.GET_REGION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get provincia list
        case surveyActions.GET_PROVINCIA_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_PROVINCIA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                provincias: payload.data
            }

        case surveyActions.GET_PROVINCIA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get comune list
        case surveyActions.GET_COMUNE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_COMUNE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                comunes: payload.data
            }

        case surveyActions.GET_COMUNE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // new design
        // survey list details
        case surveyActions.GET_SURVEY_LIST_DETAILS_REQUEST:
            return {
                ...state
            }

        case surveyActions.GET_SURVEY_LIST_DETAILS_SUCCESS:
            return {
                ...state,
                surveysListDetails: payload.data
            }

        case surveyActions.GET_SURVEY_LIST_DETAILS_FAILURE:
            return {
                ...state,
                errorMsg: payload
            }

        // stop survey
        case surveyActions.STOP_SURVEY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.STOP_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.STOP_SURVEY_FAILURE:
            return {
                ...state,
                loading: false
            }

        // delete survey
        case surveyActions.DELETE_SURVEY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.DELETE_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.DELETE_SURVEY_FAILURE:
            return {
                ...state,
                loading: false
            }

        // update survey details
        case surveyActions.UPDATE_SURVEY_DETAILS_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.UPDATE_SURVEY_DETAILS_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.UPDATE_SURVEY_DETAILS_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false
            }

        // add survey sponsor
        case surveyActions.ADD_SURVEY_SPONSOR_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.ADD_SURVEY_SPONSOR_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.ADD_SURVEY_SPONSOR_FAILURE:
            return {
                ...state,
                loading: false
            }

        // add survey question
        case surveyActions.ADD_SURVEY_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.ADD_SURVEY_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.ADD_SURVEY_QUESTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // edit survey question
        case surveyActions.EDIT_SURVEY_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.EDIT_SURVEY_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.EDIT_SURVEY_QUESTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // add survey question option
        case surveyActions.ADD_SURVEY_QUESTION_OPTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.ADD_SURVEY_QUESTION_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.ADD_SURVEY_QUESTION_OPTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // add survey preliminary
        case surveyActions.ADD_SURVEY_PRELIMINARY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.ADD_SURVEY_PRELIMINARY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.ADD_SURVEY_PRELIMINARY_FAILURE:
            return {
                ...state,
                loading: false
            }

        // edit survey preliminary
        case surveyActions.EDIT_SURVEY_PRELIMINARY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.EDIT_SURVEY_PRELIMINARY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.EDIT_SURVEY_PRELIMINARY_FAILURE:
            return {
                ...state,
                loading: false
            }

        // add survey preliminary option
        case surveyActions.ADD_SURVEY_PRELIMINARY_OPTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.ADD_SURVEY_PRELIMINARY_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.ADD_SURVEY_PRELIMINARY_OPTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // survey app selection count
        case surveyActions.SURVEY_APP_SELECTION_COUNT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.SURVEY_APP_SELECTION_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                surveyAppSelectionCount: payload.data.length
            }

        case surveyActions.SURVEY_APP_SELECTION_COUNT_FAILURE:
            return {
                ...state,
                loading: false
            }

        // survey app selection count refresh
        case surveyActions.SURVEY_REFRESH_APP_SELECTION_COUNT_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.SURVEY_REFRESH_APP_SELECTION_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                surveyAppSelectionCount: payload.data
            }

        case surveyActions.SURVEY_REFRESH_APP_SELECTION_COUNT_FAILURE:
            return {
                ...state,
                loading: false
            }

        // update survey app selection
        case surveyActions.UPDATE_SURVEY_TARGET_SELECTION_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.UPDATE_SURVEY_TARGET_SELECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.UPDATE_SURVEY_TARGET_SELECTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // get user filter country list
        case surveyActions.GET_USER_FILTER_COUNTRY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countriesUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_COUNTRY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user filter state list
        case surveyActions.GET_USER_FILTER_STATE_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                statesUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_STATE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user filter city list
        case surveyActions.GET_USER_FILTER_CITY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                citiesUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user filter region list
        case surveyActions.GET_USER_FILTER_REGION_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_REGION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                regionsUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_REGION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user filter provincia list
        case surveyActions.GET_USER_FILTER_PROVINCIA_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_PROVINCIA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                provinciasUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_PROVINCIA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user filter comune list
        case surveyActions.GET_USER_FILTER_COMUNE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_USER_FILTER_COMUNE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                comunesUserFilter: payload.data
            }

        case surveyActions.GET_USER_FILTER_COMUNE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // edit survey prize
        case surveyActions.EDIT_SURVEY_PRIZE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.EDIT_SURVEY_PRIZE_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.EDIT_SURVEY_PRIZE_FAILURE:
            return {
                ...state,
                loading: false
            }

        // edit survey sponsor
        case surveyActions.EDIT_SURVEY_SPONSOR_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.EDIT_SURVEY_SPONSOR_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.EDIT_SURVEY_SPONSOR_FAILURE:
            return {
                ...state,
                loading: false
            }

        // delete survey question
        case surveyActions.DELETE_SURVEY_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.DELETE_SURVEY_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                isSurveyStatusChange: true
            }

        case surveyActions.DELETE_SURVEY_QUESTION_FAILURE:
            return {
                ...state,
                loading: false
            }

        // send friend request
        case surveyActions.SEND_FRIEND_REQUEST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.SEND_FRIEND_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case surveyActions.SEND_FRIEND_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }

        // clear app selection count
        case "CLEAR_APP_SELECTION_COUNT":
            return {
                ...state,
                surveyAppSelectionCount: null
            }

        case "SURVEY_STATUS_CHANGE":
            return {
                ...state,
                isSurveyStatusChange: false
            }

        default: return state;
    }
};