import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";

const SurveyFilterBody = ({ filter, setFilter, state, surveyActions, search, setIndex }) => {

  const dispatch = useDispatch();

  const handleClearAllFilter = () => {
    setFilter({
      surveyStatus: null,
      fromDate: "",
      toDate: ""
    });
    if (state !== null) {
      dispatch(surveyActions.getSurveyListDetails(search, state?.id, state?.users, state?.surveys, state?.groups, {
        surveyStatus: null,
        fromDate: "",
        toDate: ""
      }));
    } else {
      dispatch(surveyActions.getSurveyListDetails(search, "", [], [], [], {
        surveyStatus: null,
        fromDate: "",
        toDate: ""
      }));
    }
    setIndex(0);
  };

  const handleApplyFilter = () => {
    if (state !== null) {
      dispatch(surveyActions.getSurveyListDetails(search, state?.id, state?.users, state?.surveys, state?.groups, filter));
    } else {
      dispatch(surveyActions.getSurveyListDetails(search, "", [], [], [], filter));
    }
    setIndex(0);
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative", height: "84vh" }}>
        <p style={{ fontWeight: "bold" }}>Survey status</p>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="surveyStatus" checked={filter.surveyStatus === true} onChange={e => setFilter({ ...filter, surveyStatus: true })} />
            Active
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="surveyStatus" checked={filter.surveyStatus === false} onChange={e => setFilter({ ...filter, surveyStatus: false })} />
            Ended
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="surveyStatus" checked={filter.surveyStatus === null} onChange={e => setFilter({ ...filter, surveyStatus: null })} />
            All
          </Label>
        </FormGroup>
        <hr />

        <p style={{ fontWeight: "bold" }}>From To Date</p>
        <FormGroup>
          <Label>From Date</Label>
          <Flatpickr
            className="form-control"
            placeholder="From Date"
            style={{ background: "#fff" }}
            value={filter.fromDate && new Date(filter.fromDate)}
            options={{
              dateFormat: "m-d-Y",
              disableMobile: true,
              maxDate: new Date()
            }}
            onChange={([e]) => {
              setFilter({ ...filter, fromDate: moment(e).format("MM/DD/YYYY") });
              if (e === "") {
                setFilter({ ...filter, toDate: "" });
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>To Date</Label>
          <Flatpickr
            className="form-control"
            placeholder="To Date"
            style={{ background: "#fff" }}
            value={filter.toDate && new Date(filter.toDate)}
            options={{
              dateFormat: "m-d-Y",
              disableMobile: true,
              minDate: filter.fromDate && new Date(filter.fromDate),
              maxDate: new Date()
            }}
            onChange={([e]) => {
              setFilter({ ...filter, toDate: moment(e).format("MM/DD/YYYY") });
            }}
          />
        </FormGroup>
        <hr />

        <Row style={{ position: "absolute", bottom: "10px", left: 0, right: 0 }}>
          <Col md="6">
            <Button outline color="warning" style={{ width: "100%" }} data-bs-dismiss="offcanvas" aria-label="Close" onClick={e => handleClearAllFilter()}>Clear All</Button>
          </Col>
          <Col md="6">
            <Button outline color="success" style={{ width: "100%" }} data-bs-dismiss="offcanvas" aria-label="Close" onClick={e => handleApplyFilter()}>Apply Filter</Button>
          </Col>
        </Row>

      </div>

    </React.Fragment>
  )
};

export default SurveyFilterBody;