import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch, useSelector } from "react-redux";

const AppSelectionModal = ({ surveyAppSelectionModal, surveyAppSelectionToggle, surveyAppSelectionCount, appSelectionData, surveyId, surveyActions, dropdownActions, surveysListDetails, surveyIndex }) => {

  const [appSelection, setAppSelection] = useState({
    surveyId: surveyId,
    gender: "",
    minAge: null,
    maxAge: null,
    country: "",
    region: "",
    city: "",
    italy_region: "",
    provincia: "",
    comune: "",
    civilState: "",
    education: ""
  });
  const [minimumAge, setMinimumAge] = useState(null);
  const [maximumAge, setMaximumAge] = useState(null);
  const dispatch = useDispatch();

  const countries = useSelector(state => state.surveyInfo.countries);
  const states = useSelector(state => state.surveyInfo.states);
  const cities = useSelector(state => state.surveyInfo.cities);
  const regions = useSelector(state => state.surveyInfo.regions);
  const provincias = useSelector(state => state.surveyInfo.provincias);
  const comunes = useSelector(state => state.surveyInfo.comunes);
  const civilStateList = useSelector(state => state.dropdownInfo.civilStateType);
  const educationList = useSelector(state => state.dropdownInfo.educationType);

  useEffect(() => {
    dispatch(surveyActions.getCountryList());
    dispatch(surveyActions.getRegionList());
    dispatch(dropdownActions.civilStateTypeList());
    dispatch(dropdownActions.educationTypeList());
  }, []);

  useEffect(() => {
    if (appSelection?.country) {
      dispatch(surveyActions.getStateList(appSelection?.country));
    }
    if (appSelection?.region) {
      dispatch(surveyActions.getCityList(appSelection?.region));
    }
    if (appSelection?.country === "107") {
      if (appSelection?.italy_region) {
        dispatch(surveyActions.getProvinciaList(appSelection?.region));
      }
      if (appSelection?.provincia) {
        dispatch(surveyActions.getComuneList(appSelection?.provincia));
      }
    }
  }, [appSelection.country, appSelection.region, appSelection.italy_region, appSelection.provincia]);

  useEffect(() => {
    setAppSelection({
      surveyId: surveyId,
      gender: appSelectionData?.gender,
      minAge: appSelectionData?.minAge ? appSelectionData.minAge : null,
      maxAge: appSelectionData?.maxAge ? appSelectionData.maxAge : null,
      country: appSelectionData?.country,
      region: appSelectionData?.region,
      city: appSelectionData?.city,
      italy_region: appSelectionData?.italy_region,
      provincia: appSelectionData?.provincia,
      comune: appSelectionData?.comune,
      civilState: appSelectionData?.civilState,
      education: appSelectionData?.education
    });
    setMinimumAge(appSelectionData?.minAge ? appSelectionData.minAge : null);
    setMaximumAge(appSelectionData?.maxAge ? appSelectionData.maxAge : null);
  }, [appSelectionData, surveyId]);

  const handleCheckAppSelectionUsersCount = () => {
    const { gender, minAge, maxAge, country, region, city, italy_region, provincia, comune, civilState, education } = appSelection;
    const query = { minage: minimumAge, maxage: maximumAge, country, region, city, civil: civilState, education, gender, italy_region, provincia, comune };
    dispatch(surveyActions.adminAppSelectionCountRefresh(query));
  };

  const handleCancelAppselection = () => {
    dispatch(surveyActions.surveyAppSelectionCount({ surveys: [surveysListDetails[surveyIndex]?._id] }));
  };

  const handleUpdateAppSelection = () => {
    dispatch(surveyActions.adminUpdateAppSelection(surveyId, { ...appSelection, surveyId, minAge: minimumAge, maxAge: maximumAge, AppSelectioncount: surveyAppSelectionCount }, surveyAppSelectionToggle));
  };

  return (
    <React.Fragment>
      <Modal isOpen={surveyAppSelectionModal} toggle={surveyAppSelectionToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Survey App Selection</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <div className="row m-auto">
            <div className="col-12 my-3" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
              <h5>Target</h5>
              <div>
                <p style={{ border: "1px solid lightgray", padding: "7px 15px", borderRadius: "7px" }}>{surveyAppSelectionCount}</p>
                <i className="fa-solid fa-arrows-rotate fa-2x text-danger" style={{ marginTop: "10px", cursor: "pointer" }} onClick={e => handleCheckAppSelectionUsersCount()}></i>
              </div>
            </div>
            <div className="col-12 my-3">
              <label
                for="validationCustom04"
                className="form-label mb-3"
              >
                Age
              </label>
              <MultiRangeSlider
                min={1}
                max={100}
                step={5}
                minValue={minimumAge}
                maxValue={maximumAge}
                onChange={(e) => {
                  setMinimumAge(e.minValue);
                  setMaximumAge(e.maxValue);
                  // setAppSelection({
                  //   ...appSelection,
                  //   minAge: e.minValue,
                  //   maxAge: e.maxValue
                  // });
                }}
              />
            </div>
            <div className="col-12 my-3">
              <label
                for="validationCustom04"
                className="form-label"
              >
                Gender
              </label>
              <div className="row">
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appSelectiongender"
                      id="appSelectiongender2"
                      checked={appSelection.gender === "male"}
                      value="male"
                      onChange={e => setAppSelection({ ...appSelection, gender: "male" })}
                    />
                    <label
                      className="form-check-label"
                      for="appSelectiongender2"
                    >
                      Male
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appSelectiongender"
                      id="appSelectiongender"
                      checked={appSelection.gender === "female"}
                      value="female"
                      onChange={e => setAppSelection({ ...appSelection, gender: "female" })}
                    />
                    <label
                      className="form-check-label"
                      for="appSelectiongender"
                    >
                      Female
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appSelectiongender"
                      id="appSelectiongender"
                      checked={appSelection.gender === ""}
                      value=""
                      onChange={e => setAppSelection({ ...appSelection, gender: "" })}
                    />
                    <label
                      className="form-check-label"
                      for="appSelectiongender"
                    >
                      All
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <label
                for="validationCustom04"
                className="form-label"
              >
                Country
              </label>
              <select
                className="form-select"
                value={appSelection.country}
                onChange={e => {
                  if (e.target.value === "") {
                    setAppSelection({
                      ...appSelection,
                      region: "",
                      city: "",
                      italy_region: "",
                      provincia: "",
                      comune: "",
                      country: ""
                    });
                  } else {
                    if (e.target.value === "107") {
                      setAppSelection({
                        ...appSelection,
                        region: "",
                        city: "",
                        country: e.target.value
                      });
                    } else if (e.target.value !== "107") {
                      setAppSelection({
                        ...appSelection,
                        italy_region: "",
                        provincia: "",
                        comune: "",
                        country: e.target.value
                      });
                    }
                  }
                }}
              >
                <option selected value="">All</option>
                {
                  countries && countries?.length > 0 && countries?.map(c => (
                    <option key={c?._id} value={c.id}>{c.name}</option>
                  ))
                }
              </select>
            </div>
            {
              appSelection?.country === "107" ?
                <React.Fragment>
                  <div className="col-12 mt-3">
                    <label
                      for="validationCustom04"
                      className="form-label"
                    >
                      Region
                    </label>
                    <select
                      className="form-select"
                      value={appSelection.italy_region}
                      onChange={e => {
                        setAppSelection({ ...appSelection, italy_region: e.target.value });
                        if (e.target.value === "") {
                          setAppSelection({
                            ...appSelection,
                            provincia: "",
                            comune: ""
                          })
                        }
                      }}
                    >
                      <option selected value="">All</option>
                      {
                        regions && regions?.length > 0 && regions?.map(s => (
                          <option key={s?._id} value={s?.IdRegione}>{s?.DescrRegione}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 mt-3">
                    <label
                      for="validationCustom04"
                      className="form-label"
                    >
                      Provincia
                    </label>
                    <select
                      className="form-select"
                      value={appSelection.provincia}
                      onChange={e => {
                        setAppSelection({ ...appSelection, provincia: e.target.value });
                        if (e.target.value === "") {
                          setAppSelection({
                            ...appSelection,
                            comune: ""
                          })
                        }
                      }}
                    >
                      <option selected value="">All</option>
                      {
                        provincias && provincias?.length > 0 && provincias?.map(c => (
                          <option key={c?._id} value={c?.IdProvincia}>{c?.DescrProvincia}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 mt-3">
                    <label
                      for="validationCustom04"
                      className="form-label"
                    >
                      Comune
                    </label>
                    <select
                      className="form-select"
                      value={appSelection.comune}
                      onChange={e => setAppSelection({ ...appSelection, comune: e.target.value })}
                    >
                      <option selected value="">All</option>
                      {
                        comunes && comunes?.length > 0 && comunes?.map(c => (
                          <option key={c?._id} value={c?.IdComune}>{c?.DescrComune}</option>
                        ))
                      }
                    </select>
                  </div>
                </React.Fragment> :
                <React.Fragment>
                  <div className="col-12 mt-3">
                    <label
                      for="validationCustom04"
                      className="form-label"
                    >
                      State
                    </label>
                    <select
                      className="form-select"
                      value={appSelection.region}
                      onChange={e => {
                        setAppSelection({ ...appSelection, region: e.target.value })
                        if (e.target.value === "") {
                          setAppSelection({ ...appSelection, city: "" })
                        }
                      }}
                    >
                      <option selected value="">All</option>
                      {
                        states && states?.length > 0 && states?.map(s => (
                          <option key={s?._id} value={s?.id}>{s?.name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 mt-3">
                    <label
                      for="validationCustom04"
                      className="form-label"
                    >
                      City
                    </label>
                    <select
                      className="form-select"
                      value={appSelection.city}
                      onChange={e => setAppSelection({ ...appSelection, city: e.target.value })}
                    >
                      <option selected value="">All</option>
                      {
                        cities && cities?.length > 0 && cities?.map(c => (
                          <option key={c?._id} value={c?.id}>{c?.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </React.Fragment>
            }
            <div className="col-12 mt-3">
              <label
                for="validationCustom04"
                className="form-label"
              >
                Civil State
              </label>
              <select
                className="form-select"
                value={appSelection.civilState}
                onChange={e => setAppSelection({ ...appSelection, civilState: e.target.value })}
              >
                <option selected value="">All</option>
                {
                  civilStateList && civilStateList?.length > 0 && civilStateList?.map(c => (
                    <option key={c?._id} value={c?.type}>{c?.type}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12 mt-3">
              <label
                for="validationCustom04"
                className="form-label"
              >
                Education
              </label>
              <select
                className="form-select"
                value={appSelection.education}
                onChange={e => setAppSelection({ ...appSelection, education: e.target.value })}
              >
                <option selected value="">All</option>
                {
                  educationList && educationList?.length > 0 && educationList?.map(c => (
                    <option key={c?._id} value={c?.type}>{c?.type}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            surveyAppSelectionToggle();
            handleCancelAppselection();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleUpdateAppSelection();
          }}>Update</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default AppSelectionModal;