import * as paymentActions from "./payment.actions";

export const paymentFeatureKey = "paymentInfo";

const initialState = {
    loading: false,
    errorMsg: ""
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case paymentActions.CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case paymentActions.CREATE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false
            }

        default: return state
    }
};