import * as htmlDocActions from "./htmlDoc.actions";

export const htmlDocFeatureKey = 'htmlDocInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    doc: {},
    commentList: [],
    isCommentStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // get html doc
        case htmlDocActions.GET_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.GET_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                doc: payload.data
            }

        case htmlDocActions.GET_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                doc: {}
            }

        // save html doc
        case htmlDocActions.SAVE_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.SAVE_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                doc: payload.data
            }

        case htmlDocActions.SAVE_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // comment html doc
        case htmlDocActions.COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // list comment html doc
        case htmlDocActions.LIST_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.LIST_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                commentList: payload.data
            }

        case htmlDocActions.LIST_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // like comment
        case htmlDocActions.LIKE_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.LIKE_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.LIKE_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // unlike comment
        case htmlDocActions.UNLIKE_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.UNLIKE_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.UNLIKE_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete comment
        case htmlDocActions.DELETE_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.DELETE_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.DELETE_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // reply comment
        case htmlDocActions.REPLY_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.REPLY_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.REPLY_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // like reply comment
        case htmlDocActions.LIKE_REPLY_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.LIKE_REPLY_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.LIKE_REPLY_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // unlike reply comment
        case htmlDocActions.UNLIKE_REPLY_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.UNLIKE_REPLY_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.UNLIKE_REPLY_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete reply comment
        case htmlDocActions.DELETE_REPLY_COMMENT_HTML_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case htmlDocActions.DELETE_REPLY_COMMENT_HTML_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCommentStatusChange: true
            }

        case htmlDocActions.DELETE_REPLY_COMMENT_HTML_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "IS_COMMENT_STATUS_CHANGE":
            return {
                ...state,
                isCommentStatusChange: false
            }

        default: return state;
    }
};