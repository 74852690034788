import * as settingActions from "./setting.actions";

export const settingsFeatureKey = "settingInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    paymentMethod: {},
    cost: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // payment method
        case settingActions.GET_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case settingActions.GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethod: payload.data
            }

        case settingActions.GET_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case settingActions.UPDATE_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case settingActions.UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethod: payload.data
            }

        case settingActions.UPDATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // Cost
        case settingActions.GET_COST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case settingActions.GET_COST_SUCCESS:
            return {
                ...state,
                loading: false,
                cost: payload.data
            }

        case settingActions.GET_COST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case settingActions.UPDATE_COST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case settingActions.UPDATE_COST_SUCCESS:
            return {
                ...state,
                loading: false,
                cost: payload.data
            }

        case settingActions.UPDATE_COST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete user permanently
        case settingActions.DELETE_USER_PERMANENTLY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case settingActions.DELETE_USER_PERMANENTLY_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case settingActions.DELETE_USER_PERMANENTLY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};