import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import * as groupActions from "../../redux/group/group.actions";
import * as dropdownActions from "../../redux/dropdown/dropdown.actions";
import '../groupPage/group.css';
import { awsBaseUrl } from "../../helper";
import FilterUser from "../filterComponents/FilterUser";
import FilterSurvey from "../filterComponents/FilterSurvey";

const FilterGroup = ({ state, setFilterComponentBack }) => {

  const [groupIndex, setGroupIndex] = useState(0);
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState("");
  const [groupStatus, setGroupStatus] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState({});
  const [filterComponentName, setFilterComponentName] = useState("");
  const [filterComponent, setFilterComponent] = useState(false);
  const [filterComponentState, setFilterComponentState] = useState({});

  const updateToggle = () => setUpdateModal(!updateModal);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const groupListDetails = useSelector((state) => state.groupInfo.groupListDetails);
  const groupTypeList = useSelector((state) => state.dropdownInfo.groupType);
  const isGroupUpdated = useSelector(state => state.groupInfo.isGroupUpdated);
  const previousProps = useRef({ isGroupUpdated }).current;

  useEffect(() => {
    if (state !== null) {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, state?.id, state?.user));
    } else {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, "", ""));
    }
    dispatch(dropdownActions.groupTypeList());
  }, [state]);

  useEffect(() => {
    if (groupListDetails && groupListDetails?.length > 0) {
      setGroups(groupListDetails);
    } else {
      setGroups([]);
    }
  }, [groupListDetails]);

  useEffect(() => {
    if (previousProps.isGroupUpdated !== isGroupUpdated) {
      if (isGroupUpdated) {
        if (state !== null) {
          dispatch(groupActions.getGroupListDetails(search, groupStatus, state?.id, state?.user));
        } else {
          dispatch(groupActions.getGroupListDetails(search, groupStatus, "", ""));
        }
      }
    }

    return () => {
      previousProps.isGroupUpdated = isGroupUpdated;
    }
  }, [isGroupUpdated]);

  useEffect(() => {
    if (state !== null) {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, state?.id, state?.user));
    } else {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, "", ""));
    }
  }, [search]);

  const handleChangeFilter = (e) => {
    setGroupStatus(e.target.value);
  };

  const handleApplyFilter = () => {
    if (state !== null) {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, state?.id, state?.user));
    } else {
      dispatch(groupActions.getGroupListDetails(search, groupStatus, "", ""));
    }
    setGroupIndex(0);
  };

  const handleClearAllFilter = () => {
    setGroupStatus("");
    setSearch("");
    if (state !== null) {
      dispatch(groupActions.getGroupListDetails("", "", state?.id, state?.user));
    } else {
      dispatch(groupActions.getGroupListDetails("", "", "", ""));
    }
    setGroupIndex(0);
  };

  const handleBackButton = () => {
    if (groupIndex !== 0) {
      setGroupIndex(groupIndex - 1);
    }
  };

  const handleNextButton = () => {
    if (groupIndex < (groupListDetails?.length - 1)) {
      setGroupIndex(groupIndex + 1);
    }
  };

  const handleDeleteButton = () => {
    dispatch(groupActions.deleteGroup(groups[0] && groups[groupIndex]?._id));
    deleteToggle();
  };

  const handleVerifyRejectGroup = (verify) => {
    dispatch(groupActions.groupVerify({ group: groups[0] && groups[groupIndex]?._id, verify }));
  };

  const handleUpdateIcon = () => {
    setSelectedGroupDetails(groups[groupIndex]);
    updateToggle();
  };

  const handleUpdateButton = () => {
    dispatch(groupActions.updateGroupDetails(selectedGroupDetails, selectedGroupDetails._id));
    updateToggle();
    setSelectedGroupDetails({});
  };

  const handleSetNavigateStateSurveyIds = (surveys) => {
    return surveys?.map(s => s?._id);
  };

  const handleNavigateOrganizerAll = () => {
    let users = groups?.map(g => g?.createdBy);
    // navigate('/user', {
    //   state: {
    //     id: '',
    //     users,
    //     emptyFilterUsers: false
    //   }
    // });
    setFilterComponent(true);
    setFilterComponentName("user");
    setFilterComponentState({
      id: '',
      users,
      emptyFilterUsers: false
    });
  };

  const handleNavigateSurveyAll = () => {
    let groupIds = groups?.map(g => g?._id);
    // navigate('/survey', { state: { id: '', users: [], surveys: [], groups: groupIds } });
    setFilterComponent(true);
    setFilterComponentName("survey");
    setFilterComponentState({
      id: '',
      users: [],
      surveys: [],
      groups: groupIds
    });
  };

  return (
    <React.Fragment>
      {
        filterComponent ?
          <React.Fragment>
            {
              filterComponentName === "user" &&
              <FilterUser state={filterComponentState} setFilterComponentBack={setFilterComponent} />
            }
            {
              filterComponentName === "survey" &&
              <FilterSurvey state={filterComponentState} setFilterComponentBack={setFilterComponent} />
            }
          </React.Fragment> :
          <React.Fragment>
            <Row>
              <Col style={{ position: "relative", width: "100%", height: "40px" }}>
                <Button color="primary" style={{ position: "absolute", right: "30px" }} onClick={e => setFilterComponentBack(false)}>Back</Button>
              </Col>
            </Row>
            <div className="row mt-4">
              {
                groupListDetails && groupListDetails?.length > 0 ?
                  <React.Fragment>
                    {
                      groups[0] && groups[groupIndex]?.verify === "P" &&
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Button color="success" onClick={e => handleVerifyRejectGroup("A")}>Verify</Button>
                            <Button color="warning" style={{ marginLeft: "10px" }} onClick={e => handleVerifyRejectGroup("R")}>Reject</Button>
                          </div>
                        </Col>
                      </Row>
                    }
                    <div className="col-md-4 col-sm-12">
                      <div className="row m-auto">
                        <div className="col-12 mt-4">
                          <p className="mb-2">Referent :</p>
                          <div className="col-12">
                            <InputGroup>
                              <input
                                type="text"
                                className="form-control w-75"
                                id="formGroupExampleInput"
                                value={groups[0] && groups[groupIndex]?.user_details[0] && groups[groupIndex]?.user_details[0]?.name}
                                disabled
                              />
                              <InputGroupText>
                                <i className="fa-solid fa-circle-arrow-right fa-2x"
                                  // onClick={e => {
                                  //   navigate(`/user`, {
                                  //     state: {
                                  //       id: groups[groupIndex]?.user_details[0]?._id,
                                  //       users: [],
                                  //       emptyFilterUsers: false
                                  //     }
                                  //   });
                                  // }}
                                  onClick={e => {
                                    setFilterComponent(true);
                                    setFilterComponentName("user");
                                    setFilterComponentState({
                                      id: groups[groupIndex]?.user_details[0]?._id,
                                      users: [],
                                      emptyFilterUsers: false
                                    })
                                  }}
                                  style={{ cursor: "pointer" }}></i>
                              </InputGroupText>
                            </InputGroup>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="row">
                            <div className="col-lg-12 col-md-9 col-sm-12">
                              <div className="col-12">
                                <label>Group Type</label>
                                <select
                                  className="form-select"
                                  id="validationCustom04"
                                  value={groups[0] && groups[groupIndex]?.groupType}
                                >
                                  <option disabled value="">
                                    Group Type
                                  </option>
                                  <hr />
                                  {
                                    groupTypeList && groupTypeList?.length > 0 && groupTypeList?.map(t => (
                                      <option value={t?._id} key={t?._id}>
                                        {t?.type}
                                      </option>
                                    ))
                                  }
                                </select>
                              </div>
                              <div className="col-12 mt-2">
                                <label>Group Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Group Name"
                                  value={groups[0] && groups[groupIndex]?.name}
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <label>Group Description</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Group Description"
                                  id="floatingTextarea2"
                                  value={groups[0] && groups[groupIndex]?.description}
                                  style={{ height: "100px" }}
                                ></textarea>
                              </div>
                              <div className="col-12 mt-2">
                                <label>Referent Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Referent Name"
                                  value={groups[0] && groups[groupIndex]?.refName}
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <label>Referent Surname</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Referent Surname"
                                  value={groups[0] && groups[groupIndex]?.refSurname}
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <label>Official Group Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Official Group Email"
                                  value={groups[0] && groups[groupIndex]?.email}
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <label>Official Group Phone</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Official Group Phone"
                                  value={groups[0] && groups[groupIndex]?.phone}
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <label>Official Group Website</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Official Group Website"
                                  value={groups[0] && groups[groupIndex]?.website}
                                />
                              </div>
                              <div className="col-12 mt-5 d-flex justify-content-end">
                                <i className="fa fa-2x mt-5 fa-solid fa-pen-to-square" aria-hidden="true" style={{ cursor: "pointer", color: "#000" }} onClick={e => handleUpdateIcon()}></i>
                                <button className="fa fa-2x mt-5 fa-trash ms-5" aria-hidden="true" style={{ cursor: "pointer", border: "none", outline: "none" }} disabled={!groups[groupIndex]?.surveys?.length > 0} onClick={e => deleteToggle()}></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div className="row">
                        <div className="col-md-6 col-sm-12" style={{ paddingLeft: "32px" }}>
                          <div className="row">
                            <div className="col-12 mt-4">
                              <Label>Surveys :</Label>
                              <div className="p-3 payment-detail survey_box">
                                <div className="col-12">
                                  {
                                    groups[groupIndex]?.surveys && groups[groupIndex]?.surveys?.length > 0 ?
                                      <React.Fragment>
                                        {
                                          groups[groupIndex]?.surveys?.map(s => (
                                            <InputGroup key={s?._id} className="mt-2">
                                              <input
                                                type="text"
                                                className="form-control w-75"
                                                id="formGroupExampleInput"
                                                disabled
                                                value={s?.title}
                                              />
                                              <InputGroupText>
                                                <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }}
                                                  // onClick={e => navigate('/survey', { state: { id: s?._id, users: [], surveys: [], groups: [] } })}
                                                  onClick={e => {
                                                    setFilterComponent(true);
                                                    setFilterComponentName("survey");
                                                    setFilterComponentState({
                                                      id: s?._id,
                                                      users: [],
                                                      surveys: [],
                                                      groups: []
                                                    });
                                                  }}
                                                ></i>
                                              </InputGroupText>
                                            </InputGroup>
                                          ))
                                        }
                                        <div>
                                          <div>
                                            <Input value={groups[groupIndex]?.surveys?.length} style={{ width: "50px", marginTop: "10px", marginBottom: "10px", float: "right" }} />
                                          </div>
                                          <div>
                                            <p style={{ display: "flex", marginTop: "10px", width: "100%", justifyContent: "end" }}>
                                              <i className="fa-solid fa-circle-arrow-right fa-3x" style={{ cursor: "pointer" }}
                                                // onClick={e => navigate('/survey', { state: { id: "", groups: [], users: [], surveys: handleSetNavigateStateSurveyIds(groups[groupIndex]?.surveys) } })}
                                                onClick={e => {
                                                  setFilterComponent(true);
                                                  setFilterComponentName("survey");
                                                  setFilterComponentState({
                                                    id: "",
                                                    groups: [],
                                                    users: [],
                                                    surveys: handleSetNavigateStateSurveyIds(groups[groupIndex]?.surveys)
                                                  });
                                                }}
                                              ></i>
                                            </p>
                                          </div>
                                        </div>
                                      </React.Fragment> :
                                      <React.Fragment>
                                        <div className="text-center" style={{ width: "100%" }}>
                                          <h5 className="text-center text-muted">No surveys</h5>
                                        </div>
                                      </React.Fragment>
                                  }
                                </div>
                                {/* <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                            <input
                              type="text"
                              className="form-control w-50"
                              id="formGroupExampleInput"
                              placeholder="2,5 $"
                            />
                            <i className="fa fa-file" aria-hidden="true"></i>
                            <input
                              type="text"
                              className="form-control w-25"
                              id="formGroupExampleInput"
                              placeholder="10 $"
                            />
                          </div> */}
                              </div>
                            </div>
                          </div>
                          <Row>
                            <Col md="12" className="mt-4">
                              {
                                groups[groupIndex]?.documents && groups[groupIndex]?.documents?.length > 0 &&
                                groups[groupIndex]?.documents?.map(d => (
                                  <Card key={d?._id} style={{ marginTop: "-10px" }}>
                                    <CardBody>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span>{d?.name}</span>
                                        <a href={`${awsBaseUrl}${d?.document}`} target="_blank" style={{ color: "#000" }}>
                                          <i className="fa fa-eye"></i>
                                        </a>
                                      </div>
                                    </CardBody>
                                  </Card>
                                ))
                              }
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </React.Fragment> :
                  <React.Fragment>
                    <div>
                      <h3 className="mt-3 text-center text-muted">No Group Found</h3>
                    </div>
                  </React.Fragment>
              }

              <div
                className="col-12"
                style={{ paddingLeft: "32px", paddingRight: "2rem" }}
              >
                <hr className="mt-4 mb-5" />
              </div>
              <div className="col-12" style={{ paddingLeft: "32px" }}>
                <div className="row">
                  <div className="col-3 d-flex align-items-center">
                    <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleBackButton()}></i>
                    <input
                      type="text"
                      className="form-control w-50 mx-3"
                      id="formGroupExampleInput"
                      disabled
                      value={`${groupIndex + 1} of ${groupListDetails && groupListDetails?.length}`}
                    />
                    <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNextButton()}></i>
                  </div>
                  {
                    groupListDetails && groupListDetails?.length > 0 &&
                    <div className="col-9 d-flex justify-content-start align-items-center">
                      <i className="fa fa-2x fa-users" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNavigateOrganizerAll()}></i>
                      <i className="fa-sharp fa-solid fa-check-to-slot fa-box-ballot ms-5 fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNavigateSurveyAll()}></i>
                    </div>
                  }
                </div>
              </div>
            </div>
            <React.Fragment>
              <Modal isOpen={updateModal} toggle={updateToggle} backdrop={"static"} className="modal-md" centered>
                <ModalBody className='p-3'>
                  <h4 className="mb-3">Group Details</h4>
                  <FormGroup>
                    <Label>Group Type</Label>
                    <Input type="select" value={selectedGroupDetails?.groupType} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, groupType: e.target.value })}>
                      <option disabled>Group Types</option>
                      {
                        groupTypeList && groupTypeList?.length > 0 && groupTypeList?.map(t => (
                          <option key={t?._id} value={t?._id}>{t?.type}</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Group Name</Label>
                    <Input value={selectedGroupDetails?.name} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, name: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Group Description</Label>
                    <textarea className="form-control" value={selectedGroupDetails?.description} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, description: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Referent Name</Label>
                    <Input value={selectedGroupDetails?.refName} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, refName: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Referent Surname</Label>
                    <Input value={selectedGroupDetails?.refSurname} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, refSurname: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Official Group Email</Label>
                    <Input value={selectedGroupDetails?.email} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, email: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Official Group Phone</Label>
                    <Input type="number" value={selectedGroupDetails?.phone} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, phone: e.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Official Group Website</Label>
                    <Input value={selectedGroupDetails?.website} onChange={e => setSelectedGroupDetails({ ...selectedGroupDetails, website: e.target.value })} />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="warning" onClick={e => {
                    handleUpdateButton();
                  }}>Update</Button>{' '}
                  <Button color="secondary" onClick={e => {
                    setSelectedGroupDetails({});
                    updateToggle();
                  }}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
            <React.Fragment>
              <Modal isOpen={deleteModal} toggle={deleteToggle} backdrop={"static"} className="modal-sm" centered>
                <ModalBody className='p-3'>
                  <h4 className='text-center'>Are you sure?</h4>
                </ModalBody>
                <ModalFooter className='m-auto'>
                  <Button color="danger" onClick={e => {
                    handleDeleteButton();
                  }}>Delete</Button>{' '}
                  <Button color="secondary" onClick={e => {
                    deleteToggle();
                  }}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default FilterGroup;
