import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const HELP_LIST_REQUEST = 'HELP_LIST_REQUEST';
export const HELP_LIST_SUCCESS = 'HELP_LIST_SUCCESS';
export const HELP_LIST_FAILURE = 'HELP_LIST_FAILURE';

export const REPLY_HELP_REQUEST = 'REPLY_HELP_REQUEST';
export const REPLY_HELP_SUCCESS = 'REPLY_HELP_SUCCESS';
export const REPLY_HELP_FAILURE = 'REPLY_HELP_FAILURE';

export const UPDATE_HELP_STATUS_REQUEST = 'UPDATE_HELP_STATUS_REQUEST';
export const UPDATE_HELP_STATUS_SUCCESS = 'UPDATE_HELP_STATUS_SUCCESS';
export const UPDATE_HELP_STATUS_FAILURE = 'UPDATE_HELP_STATUS_FAILURE';

export const getHelpList = (search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: HELP_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/ticket/list/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: HELP_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: HELP_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const replyHelp = (id, data, setDescription, setAttachment) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "HELP_STATUS_CHANGE" });
            dispatch({ type: REPLY_HELP_REQUEST });
            // upload image in aws bucket
            if (data.attachment && data.attachment.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                    sFileName: data.attachment.file.name,
                    sContentType: data.attachment.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.attachment.file, { headers: { "Content-Type": data.attachment.file.type } });
                const url = `${process.env.REACT_APP_BASE_URL}/admin/reply/ticket/${id}/v1`;
                const res = await axios.put(url, { ...data, attachment: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: REPLY_HELP_SUCCESS, payload: res.data });
                setDescription("");
                setAttachment("");
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/admin/reply/ticket/${id}/v1`;
                const res = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                dispatch({ type: REPLY_HELP_SUCCESS, payload: res.data });
                setDescription("");
                setAttachment("");
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: REPLY_HELP_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateHelpStatus = (id, option, setOption, editHelpStatusToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "HELP_STATUS_CHANGE" });
            dispatch({ type: UPDATE_HELP_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/change/ticket/status/${id}/v1`;
            const res = await axios.put(url, { ticketStatus: option }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_HELP_STATUS_SUCCESS, payload: res.data });
            toast("Help Status Updated");
            setOption("");
            editHelpStatusToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_HELP_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};