import * as userActions from './user.actions';

export const userFeatureKey = 'userInfo';

const initialState = {
    loading: false,
    user: {},
    usersDetails: [],
    emailSent: false,
    errorMsg: "",
    isUpdateUserStatus: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // login
        case userActions.LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true,
                emailSent: false
            }

        case userActions.LOGIN_USER_SUCCESS:
            localStorage.setItem('Authorization', payload.token);
            return {
                ...state,
                loading: false,
                emailSent: true
            }

        case userActions.LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                emailSent: false
            }

        // social login
        case userActions.SOCIAL_LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.SOCIAL_LOGIN_USER_SUCCESS:
            localStorage.setItem('Authorization', payload.token);
            return {
                ...state,
                loading: false
            }

        case userActions.SOCIAL_LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get login user
        case userActions.GET_LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.GET_LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: payload.data
            }

        case userActions.GET_LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update user details
        case userActions.UPDATE_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: payload.data
            }

        case userActions.UPDATE_USER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // forgot user password
        case userActions.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case userActions.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // new design
        // get user list details
        case userActions.GET_USER_LIST_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.GET_USER_LIST_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                usersDetails: payload.data
            }

        case userActions.GET_USER_LIST_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update user details
        case userActions.UPDATE_USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.UPDATE_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdateUserStatus: true
            }

        case userActions.UPDATE_USER_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // send email to users
        case userActions.SEND_EMAIL_TO_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.SEND_EMAIL_TO_USERS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.SEND_EMAIL_TO_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // send notification to users
        case userActions.SEND_NOTIFICATION_TO_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.SEND_NOTIFICATION_TO_USERS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.SEND_NOTIFICATION_TO_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update user status
        case userActions.ACTIVE_INACTIVE_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userActions.ACTIVE_INACTIVE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdateUserStatus: true
            }

        case userActions.ACTIVE_INACTIVE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_UPDATE_USER_STATUS":
            return {
                ...state,
                isUpdateUserStatus: false
            }

        default: return state;
    }
};