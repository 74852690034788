import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const SendEmailModal = ({ sendEmailModal, sendEmailToggle, userActions, emailUsers, setEmailUsers }) => {

  const [emailData, setEmailData] = useState({
    subject: "",
    body: "",
    users: []
  });
  const dispatch = useDispatch();

  const handleSendEmail = () => {
    dispatch(userActions.sendEmailToUsers({ ...emailData, users: emailUsers }, sendEmailToggle, clearState));
  };

  const clearState = () => {
    setEmailData({
      subject: "",
      body: "",
      users: [],
    });
    setEmailUsers([]);
  };

  return (
    <React.Fragment>
      <Modal isOpen={sendEmailModal} toggle={sendEmailToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Email</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Row>
            <Col>
              <FormGroup>
                <Label>Subject</Label>
                <Input type="text" value={emailData.subject} onChange={e => setEmailData({ ...emailData, subject: e.target.value })} />
              </FormGroup>
              <FormGroup>
                <Label>Body</Label>
                <Input type="textarea" rows={9} value={emailData.body} onChange={e => setEmailData({ ...emailData, body: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            sendEmailToggle();
            clearState();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleSendEmail();
          }}>Send</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default SendEmailModal;