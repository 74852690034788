import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const GET_SURVEY_QUESTIONS_REQUEST = 'GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE = 'GET_SURVEY_QUESTIONS_FAILURE';

export const GET_SURVEY_RESULT_REQUEST = 'GET_SURVEY_RESULT_REQUEST';
export const GET_SURVEY_RESULT_SUCCESS = 'GET_SURVEY_RESULT_SUCCESS';
export const GET_SURVEY_RESULT_FAILURE = 'GET_SURVEY_RESULT_FAILURE';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';

export const GET_REGION_LIST_REQUEST = 'GET_REGION_LIST_REQUEST';
export const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS';
export const GET_REGION_LIST_FAILURE = 'GET_REGION_LIST_FAILURE';

export const GET_PROVINCIA_LIST_REQUEST = 'GET_PROVINCIA_LIST_REQUEST';
export const GET_PROVINCIA_LIST_SUCCESS = 'GET_PROVINCIA_LIST_SUCCESS';
export const GET_PROVINCIA_LIST_FAILURE = 'GET_PROVINCIA_LIST_FAILURE';

export const GET_COMUNE_LIST_REQUEST = 'GET_COMUNE_LIST_REQUEST';
export const GET_COMUNE_LIST_SUCCESS = 'GET_COMUNE_LIST_SUCCESS';
export const GET_COMUNE_LIST_FAILURE = 'GET_COMUNE_LIST_FAILURE';

// new design
export const GET_SURVEY_LIST_DETAILS_REQUEST = 'GET_SURVEY_LIST_DETAILS_REQUEST';
export const GET_SURVEY_LIST_DETAILS_SUCCESS = 'GET_SURVEY_LIST_DETAILS_SUCCESS';
export const GET_SURVEY_LIST_DETAILS_FAILURE = 'GET_SURVEY_LIST_DETAILS_FAILURE';

export const STOP_SURVEY_REQUEST = 'STOP_SURVEY_REQUEST';
export const STOP_SURVEY_SUCCESS = 'STOP_SURVEY_SUCCESS';
export const STOP_SURVEY_FAILURE = 'STOP_SURVEY_FAILURE';

export const DELETE_SURVEY_REQUEST = 'DELETE_SURVEY_REQUEST';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_FAILURE = 'DELETE_SURVEY_FAILURE';

export const UPDATE_SURVEY_DETAILS_SETTINGS_REQUEST = 'UPDATE_SURVEY_DETAILS_SETTINGS_REQUEST';
export const UPDATE_SURVEY_DETAILS_SETTINGS_SUCCESS = 'UPDATE_SURVEY_DETAILS_SETTINGS_SUCCESS';
export const UPDATE_SURVEY_DETAILS_SETTINGS_FAILURE = 'UPDATE_SURVEY_DETAILS_SETTINGS_FAILURE';

export const UPDATE_SURVEY_SPONSOR_IMAGE_REQUEST = 'UPDATE_SURVEY_SPONSOR_IMAGE_REQUEST';
export const UPDATE_SURVEY_SPONSOR_IMAGE_SUCCESS = 'UPDATE_SURVEY_SPONSOR_IMAGE_SUCCESS';
export const UPDATE_SURVEY_SPONSOR_IMAGE_FAILURE = 'UPDATE_SURVEY_SPONSOR_IMAGE_FAILURE';

export const UPDATE_SURVEY_PRIZE_IMAGE_REQUEST = 'UPDATE_SURVEY_PRIZE_IMAGE_REQUEST';
export const UPDATE_SURVEY_PRIZE_IMAGE_SUCCESS = 'UPDATE_SURVEY_PRIZE_IMAGE_SUCCESS';
export const UPDATE_SURVEY_PRIZE_IMAGE_FAILURE = 'UPDATE_SURVEY_PRIZE_IMAGE_FAILURE';

export const ADD_SURVEY_SPONSOR_REQUEST = 'ADD_SURVEY_SPONSOR_REQUEST';
export const ADD_SURVEY_SPONSOR_SUCCESS = 'ADD_SURVEY_SPONSOR_SUCCESS';
export const ADD_SURVEY_SPONSOR_FAILURE = 'ADD_SURVEY_SPONSOR_FAILURE';

export const ADD_SURVEY_QUESTION_REQUEST = 'ADD_SURVEY_QUESTION_REQUEST';
export const ADD_SURVEY_QUESTION_SUCCESS = 'ADD_SURVEY_QUESTION_SUCCESS';
export const ADD_SURVEY_QUESTION_FAILURE = 'ADD_SURVEY_QUESTION_FAILURE';

export const EDIT_SURVEY_QUESTION_REQUEST = 'EDIT_SURVEY_QUESTION_REQUEST';
export const EDIT_SURVEY_QUESTION_SUCCESS = 'EDIT_SURVEY_QUESTION_SUCCESS';
export const EDIT_SURVEY_QUESTION_FAILURE = 'EDIT_SURVEY_QUESTION_FAILURE';

export const ADD_SURVEY_QUESTION_OPTION_REQUEST = 'ADD_SURVEY_QUESTION_OPTION_REQUEST';
export const ADD_SURVEY_QUESTION_OPTION_SUCCESS = 'ADD_SURVEY_QUESTION_OPTION_SUCCESS';
export const ADD_SURVEY_QUESTION_OPTION_FAILURE = 'ADD_SURVEY_QUESTION_OPTION_FAILURE';

export const ADD_SURVEY_PRELIMINARY_REQUEST = 'ADD_SURVEY_PRELIMINARY_REQUEST';
export const ADD_SURVEY_PRELIMINARY_SUCCESS = 'ADD_SURVEY_PRELIMINARY_SUCCESS';
export const ADD_SURVEY_PRELIMINARY_FAILURE = 'ADD_SURVEY_PRELIMINARY_FAILURE';

export const EDIT_SURVEY_PRELIMINARY_REQUEST = 'EDIT_SURVEY_PRELIMINARY_REQUEST';
export const EDIT_SURVEY_PRELIMINARY_SUCCESS = 'EDIT_SURVEY_PRELIMINARY_SUCCESS';
export const EDIT_SURVEY_PRELIMINARY_FAILURE = 'EDIT_SURVEY_PRELIMINARY_FAILURE';

export const ADD_SURVEY_PRELIMINARY_OPTION_REQUEST = 'ADD_SURVEY_PRELIMINARY_OPTION_REQUEST';
export const ADD_SURVEY_PRELIMINARY_OPTION_SUCCESS = 'ADD_SURVEY_PRELIMINARY_OPTION_SUCCESS';
export const ADD_SURVEY_PRELIMINARY_OPTION_FAILURE = 'ADD_SURVEY_PRELIMINARY_OPTION_FAILURE';

export const SURVEY_APP_SELECTION_COUNT_REQUEST = 'SURVEY_APP_SELECTION_COUNT_REQUEST';
export const SURVEY_APP_SELECTION_COUNT_SUCCESS = 'SURVEY_APP_SELECTION_COUNT_SUCCESS';
export const SURVEY_APP_SELECTION_COUNT_FAILURE = 'SURVEY_APP_SELECTION_COUNT_FAILURE';

export const SURVEY_REFRESH_APP_SELECTION_COUNT_REQUEST = 'SURVEY_REFRESH_APP_SELECTION_COUNT_REQUEST';
export const SURVEY_REFRESH_APP_SELECTION_COUNT_SUCCESS = 'SURVEY_REFRESH_APP_SELECTION_COUNT_SUCCESS';
export const SURVEY_REFRESH_APP_SELECTION_COUNT_FAILURE = 'SURVEY_REFRESH_APP_SELECTION_COUNT_FAILURE';

export const UPDATE_SURVEY_TARGET_SELECTION_REQUEST = 'UPDATE_SURVEY_TARGET_SELECTION_REQUEST';
export const UPDATE_SURVEY_TARGET_SELECTION_SUCCESS = 'UPDATE_SURVEY_TARGET_SELECTION_SUCCESS';
export const UPDATE_SURVEY_TARGET_SELECTION_FAILURE = 'UPDATE_SURVEY_TARGET_SELECTION_FAILURE';

export const GET_USER_FILTER_COUNTRY_REQUEST = 'GET_USER_FILTER_COUNTRY_REQUEST';
export const GET_USER_FILTER_COUNTRY_SUCCESS = 'GET_USER_FILTER_COUNTRY_SUCCESS';
export const GET_USER_FILTER_COUNTRY_FAILURE = 'GET_USER_FILTER_COUNTRY_FAILURE';

export const GET_USER_FILTER_STATE_REQUEST = 'GET_USER_FILTER_STATE_REQUEST';
export const GET_USER_FILTER_STATE_SUCCESS = 'GET_USER_FILTER_STATE_SUCCESS';
export const GET_USER_FILTER_STATE_FAILURE = 'GET_USER_FILTER_STATE_FAILURE';

export const GET_USER_FILTER_CITY_REQUEST = 'GET_USER_FILTER_CITY_REQUEST';
export const GET_USER_FILTER_CITY_SUCCESS = 'GET_USER_FILTER_CITY_SUCCESS';
export const GET_USER_FILTER_CITY_FAILURE = 'GET_USER_FILTER_CITY_FAILURE';

export const GET_USER_FILTER_REGION_LIST_REQUEST = 'GET_USER_FILTER_REGION_LIST_REQUEST';
export const GET_USER_FILTER_REGION_LIST_SUCCESS = 'GET_USER_FILTER_REGION_LIST_SUCCESS';
export const GET_USER_FILTER_REGION_LIST_FAILURE = 'GET_USER_FILTER_REGION_LIST_FAILURE';

export const GET_USER_FILTER_PROVINCIA_LIST_REQUEST = 'GET_USER_FILTER_PROVINCIA_LIST_REQUEST';
export const GET_USER_FILTER_PROVINCIA_LIST_SUCCESS = 'GET_USER_FILTER_PROVINCIA_LIST_SUCCESS';
export const GET_USER_FILTER_PROVINCIA_LIST_FAILURE = 'GET_USER_FILTER_PROVINCIA_LIST_FAILURE';

export const GET_USER_FILTER_COMUNE_LIST_REQUEST = 'GET_USER_FILTER_COMUNE_LIST_REQUEST';
export const GET_USER_FILTER_COMUNE_LIST_SUCCESS = 'GET_USER_FILTER_COMUNE_LIST_SUCCESS';
export const GET_USER_FILTER_COMUNE_LIST_FAILURE = 'GET_USER_FILTER_COMUNE_LIST_FAILURE';

export const EDIT_SURVEY_PRIZE_REQUEST = 'EDIT_SURVEY_PRIZE_REQUEST';
export const EDIT_SURVEY_PRIZE_SUCCESS = 'EDIT_SURVEY_PRIZE_SUCCESS';
export const EDIT_SURVEY_PRIZE_FAILURE = 'EDIT_SURVEY_PRIZE_FAILURE';

export const EDIT_SURVEY_SPONSOR_REQUEST = 'EDIT_SURVEY_SPONSOR_REQUEST';
export const EDIT_SURVEY_SPONSOR_SUCCESS = 'EDIT_SURVEY_SPONSOR_SUCCESS';
export const EDIT_SURVEY_SPONSOR_FAILURE = 'EDIT_SURVEY_SPONSOR_FAILURE';

export const DELETE_SURVEY_QUESTION_REQUEST = 'DELETE_SURVEY_QUESTION_REQUEST';
export const DELETE_SURVEY_QUESTION_SUCCESS = 'DELETE_SURVEY_QUESTION_SUCCESS';
export const DELETE_SURVEY_QUESTION_FAILURE = 'DELETE_SURVEY_QUESTION_FAILURE';

export const SEND_FRIEND_REQUEST_REQUEST = 'SEND_FRIEND_REQUEST_REQUEST';
export const SEND_FRIEND_REQUEST_SUCCESS = 'SEND_FRIEND_REQUEST_SUCCESS';
export const SEND_FRIEND_REQUEST_FAILURE = 'SEND_FRIEND_REQUEST_FAILURE';

export const getSurveyQuestions = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SURVEY_QUESTIONS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/survey/questions/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_SURVEY_QUESTIONS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SURVEY_QUESTIONS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getSurveyResult = (id, question, gender, minage, maxage, country, region, city, education, civil, italy_region, proviancia, comune, body, userId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SURVEY_RESULT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/survey/result/${id}/${question}/v1?gender=${gender}&minage=${minage}=&maxage=${maxage}&country=${country}&region=${region}&city=${city}&education=${education}&civil=${civil}&italy_region=${italy_region}&proviancia=${proviancia}&comune=${comune}&userId=${userId}`;
            const res = await axios.put(url, body);
            dispatch({ type: GET_SURVEY_RESULT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SURVEY_RESULT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getCountryList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COUNTRY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/country/code/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_COUNTRY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COUNTRY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getStateList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_STATE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/state/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_STATE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_STATE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getCityList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/city/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_CITY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CITY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getRegionList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_REGION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/region/list/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_REGION_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_REGION_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getProvinciaList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PROVINCIA_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/provincia/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_PROVINCIA_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PROVINCIA_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getComuneList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COMUNE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/comune/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_COMUNE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COMUNE_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

// new design
export const getSurveyListDetails = (search, id, users, surveys, groups, filter) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SURVEY_LIST_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/survey/list/details/v1?search=${search}&id=${id}`;
            const res = await axios.post(url, { users, surveys, groups, filter }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_SURVEY_LIST_DETAILS_SUCCESS, payload: res.data })
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SURVEY_LIST_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const stopSurvey = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: STOP_SURVEY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/survey/stop/v1`;
            const res = await axios.put(url, { id }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey stopped successfully.");
            dispatch({ type: STOP_SURVEY_SUCCESS, payload: res.data })
        } catch (error) {
            console.error(error);
            dispatch({ type: STOP_SURVEY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteSurvey = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_SURVEY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/survey/delete/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Delete & Stop Successfully.");
            dispatch({ type: DELETE_SURVEY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_SURVEY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminUpdateSurveyDetailsSettings = (id, data, surveySettingToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: UPDATE_SURVEY_DETAILS_SETTINGS_REQUEST });
            // upload image in aws bucket
            if (data.surveyDetails && data.surveyDetails.banner && data.surveyDetails.banner.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                    sFileName: data.surveyDetails.banner.file.name,
                    sContentType: data.surveyDetails.banner.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.surveyDetails.banner.file, { headers: { "Content-Type": data.surveyDetails.banner.file.type } });
                const url = `${process.env.REACT_APP_BASE_URL}/admin/update/survey/details/${id}/v1`;
                // { ...data.surveyDetails, banner: sImage }
                const res = await axios.put(url, { surveyDetails: { ...data.surveyDetails, banner: sImage }, surveySetting: data.surveySetting }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Updated Successfully.");
                dispatch({ type: UPDATE_SURVEY_DETAILS_SETTINGS_SUCCESS, payload: res.data });
                surveySettingToggle();
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/admin/update/survey/details/${id}/v1`;
                const res = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Updated Successfully.");
                dispatch({ type: UPDATE_SURVEY_DETAILS_SETTINGS_SUCCESS, payload: res.data });
                surveySettingToggle();
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SURVEY_DETAILS_SETTINGS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminUpdateSurveySponsorImage = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_SURVEY_SPONSOR_IMAGE_REQUEST });
            // upload image in aws bucket
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                sFileName: data.file.name,
                sContentType: data.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            const surl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            const putRes = await axios.put(surl, data.file, { headers: { "Content-Type": data.file.type } });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/survey/sponsor/image/${id}/v1`;
            const res = await axios.put(url, { image: sImage }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Sponsor Image Updated Successfully.");
            dispatch({ type: UPDATE_SURVEY_SPONSOR_IMAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SURVEY_SPONSOR_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminUpdateSurveyPrizeImage = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_SURVEY_PRIZE_IMAGE_REQUEST });
            // upload image in aws bucket
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                sFileName: data.file.name,
                sContentType: data.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            const surl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            const putRes = await axios.put(surl, data.file, { headers: { "Content-Type": data.file.type } });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/survey/prize/image/${id}/v1`;
            const res = await axios.put(url, { image: sImage }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Prize Image Updated Successfully.");
            dispatch({ type: UPDATE_SURVEY_PRIZE_IMAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SURVEY_PRIZE_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminAddSurveySponsor = (data, addSponsorToggle, setSponsor, setSelectedImage) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: ADD_SURVEY_SPONSOR_REQUEST });
            // upload image in aws bucket
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                sFileName: data.image.file.name,
                sContentType: data.image.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            const surl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/add/survey/sponsor/v1`;
            const res = await axios.post(url, { ...data, image: sImage }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Sponsor Added Successfully.");
            dispatch({ type: ADD_SURVEY_SPONSOR_SUCCESS, payload: res.data });
            addSponsorToggle();
            setSponsor({
                image: "",
                name: "",
                link: ""
            });
            setSelectedImage("");
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_SURVEY_SPONSOR_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminAddSurveyQuestion = (data, addQuestionToggle, setQuestion) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: ADD_SURVEY_QUESTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/add/survey/question/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Question Added Successfully.");
            dispatch({ type: ADD_SURVEY_QUESTION_SUCCESS, payload: res.data });
            addQuestionToggle();
            setQuestion({
                surveyId: "",
                questionType: "",
                title: "",
                options: [],
                counts: [],
                mandatory: false,
                comment: false
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_SURVEY_QUESTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminEditSurveyQuestion = (id, data, editQuestionToggle, setQuestion) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: EDIT_SURVEY_QUESTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/survey/question/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Question Updated Successfully.");
            dispatch({ type: EDIT_SURVEY_QUESTION_SUCCESS, payload: res.data });
            editQuestionToggle();
            setQuestion({
                surveyId: "",
                questionType: "",
                title: "",
                options: [],
                counts: [],
                mandatory: false,
                comment: false
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_SURVEY_QUESTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminAddSurveyOption = (id, data, addOptionToggle, setOption) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: ADD_SURVEY_QUESTION_OPTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/add/survey/question/option/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Question Option Added Successfully.");
            dispatch({ type: ADD_SURVEY_QUESTION_OPTION_SUCCESS, payload: res.data });
            addOptionToggle();
            setOption("");
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_SURVEY_QUESTION_OPTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminAddSurveyPreliminary = (data, addPreliminaryToggle, setQuestion, setSurvey) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: ADD_SURVEY_PRELIMINARY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/add/preliminary/question/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Preliminary Question Added Successfully.");
            dispatch({ type: ADD_SURVEY_PRELIMINARY_SUCCESS, payload: res.data });
            addPreliminaryToggle();
            setQuestion({
                surveyId: "",
                question: "",
                correctAnswer: "",
                options: [""],
                points: [0],
                isMaxTime: false,
                maxTime: null,
                mandatory: false
            });
            setSurvey({
                preliminary: false,
                preliminaryQuestionType: null,
                requiredPoints: null
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_SURVEY_PRELIMINARY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminEditSurveyPreliminary = (id, data, editPreliminaryToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: EDIT_SURVEY_PRELIMINARY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/preliminary/question/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Preliminary Question Updated Successfully.");
            dispatch({ type: EDIT_SURVEY_PRELIMINARY_SUCCESS, payload: res.data });
            editPreliminaryToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_SURVEY_PRELIMINARY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminAddSurveyPreliminaryOption = (id, data, addPreliminaryOptionToggle, clearState) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: ADD_SURVEY_PRELIMINARY_OPTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/add/preliminary/question/option/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Preliminary Question Updated Successfully.");
            dispatch({ type: ADD_SURVEY_PRELIMINARY_OPTION_SUCCESS, payload: res.data });
            addPreliminaryOptionToggle();
            clearState();
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_SURVEY_PRELIMINARY_OPTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const surveyAppSelectionCount = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SURVEY_APP_SELECTION_COUNT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/survey/target/slection/users/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: SURVEY_APP_SELECTION_COUNT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: SURVEY_APP_SELECTION_COUNT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const clearsurveyAppSelectionCount = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_APP_SELECTION_COUNT" });
        } catch (error) {
            console.error(error);
        }
    }
};

export const adminAppSelectionCountRefresh = (query) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SURVEY_REFRESH_APP_SELECTION_COUNT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/target/selection/v1?minage=${query.minage}&maxage=${query.maxage}&country=${query.country}&region=${query.region}&city=${query.city}&civil=${query.civil}&education=${query.education}&gender=${query.gender}&italy_region=${query.italy_region}&provincia=${query.provincia}&comune=${query.comune}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: SURVEY_REFRESH_APP_SELECTION_COUNT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: SURVEY_REFRESH_APP_SELECTION_COUNT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminUpdateAppSelection = (id, data, surveyAppSelectionToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: UPDATE_SURVEY_TARGET_SELECTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/survey/target/selection/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_SURVEY_TARGET_SELECTION_SUCCESS, payload: res.data });
            surveyAppSelectionToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SURVEY_TARGET_SELECTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterCountryList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_COUNTRY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/country/code/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_COUNTRY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_COUNTRY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterStateList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_STATE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/state/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_STATE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_STATE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterCityList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_CITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/city/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_CITY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_CITY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterRegionList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_REGION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/region/list/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_REGION_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_REGION_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterProvinciaList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_PROVINCIA_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/provincia/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_PROVINCIA_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_PROVINCIA_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserFilterComuneList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_FILTER_COMUNE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/comune/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_USER_FILTER_COMUNE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_FILTER_COMUNE_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminEditSurveyPrize = (id, data, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: EDIT_SURVEY_PRIZE_REQUEST });

            // upload image in aws bucket
            if (data && data.image && data.image.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/survey/prize/${id}/v1`;
                const res = await axios.put(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Prize Updated Successfully.");
                dispatch({ type: EDIT_SURVEY_PRIZE_SUCCESS, payload: res.data });
                toggle();
            } else {

                const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/survey/prize/${id}/v1`;
                const res = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Prize Updated Successfully.");
                dispatch({ type: EDIT_SURVEY_PRIZE_SUCCESS, payload: res.data });
                toggle();
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_SURVEY_PRIZE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminEditSurveySposnor = (id, data, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: EDIT_SURVEY_SPONSOR_REQUEST });

            // upload image in aws bucket
            if (data && data.image && data.image.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/upload/document/pre-signed-url/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/survey/sponsor/${id}/v1`;
                const res = await axios.put(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Prize Updated Successfully.");
                dispatch({ type: EDIT_SURVEY_SPONSOR_SUCCESS, payload: res.data });
                toggle();
            } else {

                const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/survey/sponsor/${id}/v1`;
                const res = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('Authorization')
                    }
                });
                toast("Survey Sponsor Updated Successfully.");
                dispatch({ type: EDIT_SURVEY_SPONSOR_SUCCESS, payload: res.data });
                toggle();
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_SURVEY_SPONSOR_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const adminDeleteSurveyQuestion = (id, setDeleteQuestionId, toggle, setQuestionIndex) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SURVEY_STATUS_CHANGE" });
            dispatch({ type: DELETE_SURVEY_QUESTION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/survey/question/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            toast("Survey Question Deleted Successfully.");
            dispatch({ type: DELETE_SURVEY_QUESTION_SUCCESS, payload: res.data });
            setDeleteQuestionId("");
            toggle();
            setQuestionIndex(0);
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_SURVEY_QUESTION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const sendFriendRequest = (id, userId, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_FRIEND_REQUEST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/friend/request/result/side/${id}/${userId}/v1`;
            const res = await axios.put(url, {});
            toast("Friend request send");
            dispatch({ type: SEND_FRIEND_REQUEST_SUCCESS, payload: res.data });
            setData(id);
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_FRIEND_REQUEST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};