import * as groupActions from './group.actions';

export const groupFeatureKey = 'groupInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    groupListDetails: [],
    isGroupUpdated: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case groupActions.VERIFY_GROUP_REQUEST:
            return {
                ...state,
                loading: false
            }

        case groupActions.VERIFY_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                isGroupUpdated: true
            }

        case groupActions.VERIFY_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case groupActions.UPDATE_GROUP_REQUEST:
            return {
                ...state,
                loading: false
            }

        case groupActions.UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                isGroupUpdated: true
            }

        case groupActions.UPDATE_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case groupActions.DELETE_GROUP_REQUEST:
            return {
                ...state,
                loading: false
            }

        case groupActions.DELETE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                isGroupUpdated: true
            }

        case groupActions.DELETE_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // new design changes
        case groupActions.GET_GROUP_LIST_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case groupActions.GET_GROUP_LIST_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                groupListDetails: payload.data
            }

        case groupActions.GET_GROUP_LIST_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "GROUP_STATE_CHANGE":
            return {
                ...state,
                isGroupUpdated: false
            }

        default: return state;
    }
};