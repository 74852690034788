import React from "react";
import { Col, Input, Row } from "reactstrap";

const Payments = () => {
  return (
    <React.Fragment>
      <Row className="mt-4 px-4">
        <Col md="1">
          <div style={{ float: "right", marginTop: "10px" }}>
            <p>Search</p>
          </div>
        </Col>
        <Col>
          <div className="search__header__input">
            <Input
              placeholder="Title or Created User"
              type="search"
              autoFocus
            />
          </div>
        </Col>
        <Col md="2">
          {" "}
          <button
            class="btn btn-primary w-100 h-75 mb-3"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <i class="fa-solid fa-sliders me-3"></i>
            Filter
          </button>
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <h5 id="offcanvasRightLabel">Filter</h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">...</div>
          </div>
        </Col>
      </Row>
      <div className="row mt-4">
        <div className="col-md-5 col-sm-12">
          <div className="row m-auto">
            <div className="col-12 mt-4">
              <p className="mb-2">Organizer :</p>
              <div className="col-12">
                <input
                  type="text"
                  class="form-control w-70"
                  id="formGroupExampleInput"
                  placeholder="Organizer"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-6">
                  <p className="mb-2">Invoice header :</p>
                  <div className="col-12">
                    <input
                      type="text"
                      class="form-control w-70"
                      id="formGroupExampleInput"
                      placeholder="Company"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <p className="mb-2">Invoice n :</p>
                  <div className="col-12">
                    <input
                      type="text"
                      class="form-control w-70"
                      id="formGroupExampleInput"
                      placeholder="Company"
                    />
                  </div>
                  <p className="mb-2 mt-4">Invoice data :</p>
                  <div className="col-12">
                    <input
                      type="text"
                      class="form-control w-70"
                      id="formGroupExampleInput"
                      placeholder="16/10/2022"
                    />
                  </div>
                  <div className="col-12 d-flex align-items-center mt-4">
                    <i class="fa fa-2x fa-file" aria-hidden="true"></i>
                    <i class="fa fa-2x fa-download ms-5" aria-hidden="true"></i>
                    <i class="fa fa-2x fa-at ms-5" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
            <i class="fa fa-2x fa-pencil mt-5" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ paddingLeft: "32px" }}>
              <div className="row">
                <div className="col-12 mt-4">
                  <p className="mb-2">Payment Details :</p>
                  <div className="p-3 payment-detail">
                    <div className="col-12 d-flex justify-content-between">
                      <p className="w-70">Invite 250 excel contacts</p>
                      <input
                        type="text"
                        class="form-control w-25"
                        id="formGroupExampleInput"
                        placeholder="2,5 $"
                      />
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-between">
                      <p className="w-70">Add user sms verification</p>
                      <input
                        type="text"
                        class="form-control w-25"
                        id="formGroupExampleInput"
                        placeholder="8 $"
                      />
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-between">
                      <p className="w-70">Add secure blockchain vote</p>
                      <input
                        type="text"
                        class="form-control w-25"
                        id="formGroupExampleInput"
                        placeholder="10 $"
                      />
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-end">
                      <h1 className="text-right">20,50 $</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-12"
          style={{ paddingLeft: "32px", paddingRight: "2rem" }}
        >
          <hr className="mt-4 mb-5" />
        </div>
        <div className="col-12" style={{ paddingLeft: "32px" }}>
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control w-50 mx-3"
                id="formGroupExampleInput"
                placeholder="458"
              />
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
            <div className="col-9 d-flex justify-content-start align-items-center">
            <i class="fa fa-2x fa-user" aria-hidden="true"></i>
              <i class="fa fa-print ms-5 fa-2x" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payments;
