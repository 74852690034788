import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const SendNotificationModal = ({ sendNotificationModal, sendNotificationToggle, userActions, notificationUsers, setNotificationUsers }) => {

  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    users: [],
    image: ""
  });
  const [selectedImage, setSelectedImage] = useState("");
  const dispatch = useDispatch();

  const handleSendNotification = () => {
    dispatch(userActions.sendNotificationToUsers({ ...notificationData, users: notificationUsers }, sendNotificationToggle, clearState));
  };

  const clearState = () => {
    setNotificationData({
      title: "",
      body: "",
      users: [],
      image: ""
    });
    setSelectedImage("");
    setNotificationUsers([]);
  };

  const handleSelectImage = (e) => {
    setSelectedImage(e.target.files[0]);
    setNotificationData({ ...notificationData, image: { file: e.target.files[0] } });
  };

  return (
    <React.Fragment>
      <Modal isOpen={sendNotificationModal} toggle={sendNotificationToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Notification</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          {
            selectedImage &&
            <Row>
              <Col>
                <img src={selectedImage ? URL.createObjectURL(selectedImage) : ""} alt="notification_image" style={{ width: "200px", marginBottom: "20px" }} />
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <FormGroup>
                <Label>Image</Label>
                <Input type="file" onChange={e => handleSelectImage(e)} accept="image/*" />
              </FormGroup>
              <FormGroup>
                <Label>Title</Label>
                <Input type="text" value={notificationData.title} onChange={e => setNotificationData({ ...notificationData, title: e.target.value })} />
              </FormGroup>
              <FormGroup>
                <Label>Body</Label>
                <Input type="textarea" rows={9} value={notificationData.body} onChange={e => setNotificationData({ ...notificationData, body: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            sendNotificationToggle();
            clearState();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleSendNotification();
          }}>Send</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default SendNotificationModal;