import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const createPayment = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_PAYMENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/payment/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: CREATE_PAYMENT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PAYMENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};