import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const GET_DOC_REQUEST = 'GET_DOC_REQUEST';
export const GET_DOC_SUCCESS = 'GET_DOC_SUCCESS';
export const GET_DOC_FAILURE = 'GET_DOC_FAILURE';

export const SAVE_DOC_REQUEST = 'SAVE_DOC_REQUEST';
export const SAVE_DOC_SUCCESS = 'SAVE_DOC_SUCCESS';
export const SAVE_DOC_FAILURE = 'SAVE_DOC_FAILURE';

export const COMMENT_HTML_DOC_REQUEST = 'COMMENT_HTML_DOC_REQUEST';
export const COMMENT_HTML_DOC_SUCCESS = 'COMMENT_HTML_DOC_SUCCESS';
export const COMMENT_HTML_DOC_FAILURE = 'COMMENT_HTML_DOC_FAILURE';

export const LIKE_COMMENT_HTML_DOC_REQUEST = 'LIKE_COMMENT_HTML_DOC_REQUEST';
export const LIKE_COMMENT_HTML_DOC_SUCCESS = 'LIKE_COMMENT_HTML_DOC_SUCCESS';
export const LIKE_COMMENT_HTML_DOC_FAILURE = 'LIKE_COMMENT_HTML_DOC_FAILURE';

export const UNLIKE_COMMENT_HTML_DOC_REQUEST = 'UNLIKE_COMMENT_HTML_DOC_REQUEST';
export const UNLIKE_COMMENT_HTML_DOC_SUCCESS = 'UNLIKE_COMMENT_HTML_DOC_SUCCESS';
export const UNLIKE_COMMENT_HTML_DOC_FAILURE = 'UNLIKE_COMMENT_HTML_DOC_FAILURE';

export const DELETE_COMMENT_HTML_DOC_REQUEST = 'DELETE_COMMENT_HTML_DOC_REQUEST';
export const DELETE_COMMENT_HTML_DOC_SUCCESS = 'DELETE_COMMENT_HTML_DOC_SUCCESS';
export const DELETE_COMMENT_HTML_DOC_FAILURE = 'DELETE_COMMENT_HTML_DOC_FAILURE';

export const REPLY_COMMENT_HTML_DOC_REQUEST = 'REPLY_COMMENT_HTML_DOC_REQUEST';
export const REPLY_COMMENT_HTML_DOC_SUCCESS = 'REPLY_COMMENT_HTML_DOC_SUCCESS';
export const REPLY_COMMENT_HTML_DOC_FAILURE = 'REPLY_COMMENT_HTML_DOC_FAILURE';

export const LIKE_REPLY_COMMENT_HTML_DOC_REQUEST = 'LIKE_REPLY_COMMENT_HTML_DOC_REQUEST';
export const LIKE_REPLY_COMMENT_HTML_DOC_SUCCESS = 'LIKE_REPLY_COMMENT_HTML_DOC_SUCCESS';
export const LIKE_REPLY_COMMENT_HTML_DOC_FAILURE = 'LIKE_REPLY_COMMENT_HTML_DOC_FAILURE';

export const UNLIKE_REPLY_COMMENT_HTML_DOC_REQUEST = 'UNLIKE_REPLY_COMMENT_HTML_DOC_REQUEST';
export const UNLIKE_REPLY_COMMENT_HTML_DOC_SUCCESS = 'UNLIKE_REPLY_COMMENT_HTML_DOC_SUCCESS';
export const UNLIKE_REPLY_COMMENT_HTML_DOC_FAILURE = 'UNLIKE_REPLY_COMMENT_HTML_DOC_FAILURE';

export const DELETE_REPLY_COMMENT_HTML_DOC_REQUEST = 'DELETE_REPLY_COMMENT_HTML_DOC_REQUEST';
export const DELETE_REPLY_COMMENT_HTML_DOC_SUCCESS = 'DELETE_REPLY_COMMENT_HTML_DOC_SUCCESS';
export const DELETE_REPLY_COMMENT_HTML_DOC_FAILURE = 'DELETE_REPLY_COMMENT_HTML_DOC_FAILURE';

export const LIST_COMMENT_HTML_DOC_REQUEST = 'LIST_COMMENT_HTML_DOC_REQUEST';
export const LIST_COMMENT_HTML_DOC_SUCCESS = 'LIST_COMMENT_HTML_DOC_SUCCESS';
export const LIST_COMMENT_HTML_DOC_FAILURE = 'LIST_COMMENT_HTML_DOC_FAILURE';

export const getOptionHtmlDoc = (uuid) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/survey/question/option/html/doc/${uuid}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const saveOptionHtmlDoc = (uuid, html) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SAVE_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/survey/question/option/html/doc/${uuid}/v1`;
            const res = await axios.put(url, { html }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: SAVE_DOC_SUCCESS, payload: res.data });
            toast("Document Saved");
        } catch (error) {
            console.error(error);
            dispatch({ type: SAVE_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const commentHtmlDoc = (data, setCommentText) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: COMMENT_HTML_DOC_SUCCESS, payload: res.data });
            setCommentText("");
        } catch (error) {
            console.error(error);
            dispatch({ type: COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const likeCommentHtmlDoc = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: LIKE_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/like/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: LIKE_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIKE_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const unlikeCommentHtmlDoc = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: UNLIKE_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/unlike/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UNLIKE_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UNLIKE_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteCommentHtmlDoc = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: DELETE_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/delete/html/doc/comment/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const replyCommentHtmlDoc = (data, setReplyCommentText) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: REPLY_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/reply/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: REPLY_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
            setReplyCommentText(['']);
        } catch (error) {
            console.error(error);
            dispatch({ type: REPLY_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const likeReplyCommentHtmlDoc = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: LIKE_REPLY_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/like/reply/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: LIKE_REPLY_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIKE_REPLY_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const unlikeReplyCommentHtmlDoc = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: UNLIKE_REPLY_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/unlike/reply/comment/html/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UNLIKE_REPLY_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UNLIKE_REPLY_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteReplyCommentHtmlDoc = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_COMMENT_STATUS_CHANGE" });
            dispatch({ type: DELETE_REPLY_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/delete/html/doc/reply/comment/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_REPLY_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_REPLY_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listCommentHtmlDoc = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_COMMENT_HTML_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/html/doc/comment/list/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: LIST_COMMENT_HTML_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_COMMENT_HTML_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};