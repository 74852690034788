import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { awsBaseUrl } from "../../helper";

const EditSurveyPrize = ({ editSurveyPrizeModal, editSurveyPrizeToggle, surveyActions, prizeData }) => {

  const [surveyPrize, setSurveyPrize] = useState({
    surveyId: "",
    image: "",
    description: "",
    numberOfPrizes: ""
  });
  const [tempImg, setTempImg] = useState("");
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(prizeData)?.length > 0) {
      setSurveyPrize({
        ...surveyPrize,
        image: prizeData?.image,
        description: prizeData?.description,
        numberOfPrizes: prizeData?.numberOfPrizes,
        surveyId: prizeData?.surveyId
      });
    }
  }, [editSurveyPrizeModal, prizeData]);

  const clickChangeIcon = () => {
    inputFileRef.current.click();
  };

  const onFileChange = (e) => {
    setTempImg(URL.createObjectURL(e.target.files[0]));
    setSurveyPrize({
      ...surveyPrize,
      image: {
        file: e.target.files[0]
      }
    });
  };

  const handleEditSurveyPrize = () => {
    dispatch(surveyActions.adminEditSurveyPrize(prizeData?._id, surveyPrize, editSurveyPrizeToggle));
  };

  return (
    <React.Fragment>
      <Modal isOpen={editSurveyPrizeModal} toggle={editSurveyPrizeToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Update Survey Prize</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Container>
            <Row>
              <Col md="3" className="mt-3 mb-3" style={{ position: "relative" }}>
                <img src={tempImg ? tempImg : `${awsBaseUrl}${surveyPrize?.image}`} style={{ height: "120px", width: "100%" }} />
                <i className="fa-solid fa-pen-to-square" style={{ position: "absolute", bottom: "5px", right: "17px", filter: "drop-shadow(3px 3px 10px #000)", cursor: "pointer", color: "#fff" }} onClick={e => clickChangeIcon()}></i>
                <input type="file" accept="image/*" style={{ display: "none" }} ref={inputFileRef} onChange={e => onFileChange(e)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Description</Label>
                  <Input type="text" value={surveyPrize.description} onChange={e => setSurveyPrize({ ...surveyPrize, description: e.target.value })} />
                </FormGroup>
                <FormGroup>
                  <Label>Number Of Prizes</Label>
                  <Input type="select" value={surveyPrize.numberOfPrizes} onChange={e => setSurveyPrize({ ...surveyPrize, numberOfPrizes: e.target.value })}>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            editSurveyPrizeToggle();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleEditSurveyPrize()
          }}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default EditSurveyPrize;