import * as helpActions from "./help.actions";

export const helpFeatureKey = "helpInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    isHelpStatusChange: false
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case helpActions.HELP_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case helpActions.HELP_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case helpActions.HELP_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case helpActions.REPLY_HELP_REQUEST:
            return {
                ...state,
                loading: true
            }

        case helpActions.REPLY_HELP_SUCCESS:
            return {
                ...state,
                loading: false,
                isHelpStatusChange: true
            }

        case helpActions.REPLY_HELP_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case helpActions.UPDATE_HELP_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case helpActions.UPDATE_HELP_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isHelpStatusChange: true
            }

        case helpActions.UPDATE_HELP_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "HELP_STATUS_CHANGE":
            return {
                ...state,
                isHelpStatusChange: false
            }

        default: return state;
    }
};