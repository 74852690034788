import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const ConfirmDeleteQuestion = ({ deleteSurveyQuestionModal, deleteSurveyQuestionToggle, surveyActions, deleteQuestionId, setDeleteQuestionId, setQuestionIndex }) => {

  const dispatch = useDispatch();

  const handleDeleteSurveyQuestion = () => {
    dispatch(surveyActions.adminDeleteSurveyQuestion(deleteQuestionId, setDeleteQuestionId, deleteSurveyQuestionToggle, setQuestionIndex));
  };

  return (
    <React.Fragment>
      <Modal isOpen={deleteSurveyQuestionModal} toggle={deleteSurveyQuestionToggle} backdrop={"static"} size="sm" centered>
        <ModalBody className='p-3'>
          <h4 className='text-center'>Are you sure?</h4>
        </ModalBody>
        <ModalFooter className='m-auto'>
          <Button color="danger" onClick={e => {
            handleDeleteSurveyQuestion();
          }}>Delete</Button>{' '}
          <Button color="secondary" onClick={e => {
            deleteSurveyQuestionToggle();
            setDeleteQuestionId("");
          }}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default ConfirmDeleteQuestion;