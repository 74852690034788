import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const CREATE_GROUP_TYPE_REQUEST = 'CREATE_GROUP_TYPE_REQUEST';
export const CREATE_GROUP_TYPE_SUCCESS = 'CREATE_GROUP_TYPE_SUCCESS';
export const CREATE_GROUP_TYPE_FAILURE = 'CREATE_GROUP_TYPE_FAILURE';

export const GET_GROUP_TYPE_LIST_REQUEST = 'GET_GROUP_TYPE_LIST_REQUEST';
export const GET_GROUP_TYPE_LIST_SUCCESS = 'GET_GROUP_TYPE_LIST_SUCCESS';
export const GET_GROUP_TYPE_LIST_FAILURE = 'GET_GROUP_TYPE_LIST_FAILURE';

export const UPDATE_GROUP_TYPE_REQUEST = 'UPDATE_GROUP_TYPE_REQUEST';
export const UPDATE_GROUP_TYPE_SUCCESS = 'UPDATE_GROUP_TYPE_SUCCESS';
export const UPDATE_GROUP_TYPE_FAILURE = 'UPDATE_GROUP_TYPE_FAILURE';

export const DELETE_GROUP_TYPE_REQUEST = 'DELETE_GROUP_TYPE_REQUEST';
export const DELETE_GROUP_TYPE_SUCCESS = 'DELETE_GROUP_TYPE_SUCCESS';
export const DELETE_GROUP_TYPE_FAILURE = 'DELETE_GROUP_TYPE_FAILURE';

// export const CREATE_QUESTION_TYPE_REQUEST = 'CREATE_QUESTION_TYPE_REQUEST';
// export const CREATE_QUESTION_TYPE_SUCCESS = 'CREATE_QUESTION_TYPE_SUCCESS';
// export const CREATE_QUESTION_TYPE_FAILURE = 'CREATE_QUESTION_TYPE_FAILURE';

export const GET_QUESTION_TYPE_LIST_REQUEST = 'GET_QUESTION_TYPE_LIST_REQUEST';
export const GET_QUESTION_TYPE_LIST_SUCCESS = 'GET_QUESTION_TYPE_LIST_SUCCESS';
export const GET_QUESTION_TYPE_LIST_FAILURE = 'GET_QUESTION_TYPE_LIST_FAILURE';

// export const UPDATE_QUESTION_TYPE_REQUEST = 'UPDATE_QUESTION_TYPE_REQUEST';
// export const UPDATE_QUESTION_TYPE_SUCCESS = 'UPDATE_QUESTION_TYPE_SUCCESS';
// export const UPDATE_QUESTION_TYPE_FAILURE = 'UPDATE_QUESTION_TYPE_FAILURE';

// export const DELETE_QUESTION_TYPE_REQUEST = 'DELETE_QUESTION_TYPE_REQUEST';
// export const DELETE_QUESTION_TYPE_SUCCESS = 'DELETE_QUESTION_TYPE_SUCCESS';
// export const DELETE_QUESTION_TYPE_FAILURE = 'DELETE_QUESTION_TYPE_FAILURE';

export const CREATE_ARGUMENT_TYPE_REQUEST = 'CREATE_ARGUMENT_TYPE_REQUEST';
export const CREATE_ARGUMENT_TYPE_SUCCESS = 'CREATE_ARGUMENT_TYPE_SUCCESS';
export const CREATE_ARGUMENT_TYPE_FAILURE = 'CREATE_ARGUMENT_TYPE_FAILURE';

export const GET_ARGUMENT_TYPE_LIST_REQUEST = 'GET_ARGUMENT_TYPE_LIST_REQUEST';
export const GET_ARGUMENT_TYPE_LIST_SUCCESS = 'GET_ARGUMENT_TYPE_LIST_SUCCESS';
export const GET_ARGUMENT_TYPE_LIST_FAILURE = 'GET_ARGUMENT_TYPE_LIST_FAILURE';

export const UPDATE_ARGUMENT_TYPE_REQUEST = 'UPDATE_ARGUMENT_TYPE_REQUEST';
export const UPDATE_ARGUMENT_TYPE_SUCCESS = 'UPDATE_ARGUMENT_TYPE_SUCCESS';
export const UPDATE_ARGUMENT_TYPE_FAILURE = 'UPDATE_ARGUMENT_TYPE_FAILURE';

export const DELETE_ARGUMENT_TYPE_REQUEST = 'DELETE_ARGUMENT_TYPE_REQUEST';
export const DELETE_ARGUMENT_TYPE_SUCCESS = 'DELETE_ARGUMENT_TYPE_SUCCESS';
export const DELETE_ARGUMENT_TYPE_FAILURE = 'DELETE_ARGUMENT_TYPE_FAILURE';

export const CREATE_CIVIL_STATE_TYPE_REQUEST = 'CREATE_CIVIL_STATE_TYPE_REQUEST';
export const CREATE_CIVIL_STATE_TYPE_SUCCESS = 'CREATE_CIVIL_STATE_TYPE_SUCCESS';
export const CREATE_CIVIL_STATE_TYPE_FAILURE = 'CREATE_CIVIL_STATE_TYPE_FAILURE';

export const GET_CIVIL_STATE_TYPE_LIST_REQUEST = 'GET_CIVIL_STATE_TYPE_LIST_REQUEST';
export const GET_CIVIL_STATE_TYPE_LIST_SUCCESS = 'GET_CIVIL_STATE_TYPE_LIST_SUCCESS';
export const GET_CIVIL_STATE_TYPE_LIST_FAILURE = 'GET_CIVIL_STATE_TYPE_LIST_FAILURE';

export const UPDATE_CIVIL_STATE_TYPE_REQUEST = 'UPDATE_CIVIL_STATE_TYPE_REQUEST';
export const UPDATE_CIVIL_STATE_TYPE_SUCCESS = 'UPDATE_CIVIL_STATE_TYPE_SUCCESS';
export const UPDATE_CIVIL_STATE_TYPE_FAILURE = 'UPDATE_CIVIL_STATE_TYPE_FAILURE';

export const DELETE_CIVIL_STATE_TYPE_REQUEST = 'DELETE_CIVIL_STATE_TYPE_REQUEST';
export const DELETE_CIVIL_STATE_TYPE_SUCCESS = 'DELETE_CIVIL_STATE_TYPE_SUCCESS';
export const DELETE_CIVIL_STATE_TYPE_FAILURE = 'DELETE_CIVIL_STATE_TYPE_FAILURE';

export const CREATE_EDUCATION_TYPE_REQUEST = 'CREATE_EDUCATION_TYPE_REQUEST';
export const CREATE_EDUCATION_TYPE_SUCCESS = 'CREATE_EDUCATION_TYPE_SUCCESS';
export const CREATE_EDUCATION_TYPE_FAILURE = 'CREATE_EDUCATION_TYPE_FAILURE';

export const GET_EDUCATION_TYPE_LIST_REQUEST = 'GET_EDUCATION_TYPE_LIST_REQUEST';
export const GET_EDUCATION_TYPE_LIST_SUCCESS = 'GET_EDUCATION_TYPE_LIST_SUCCESS';
export const GET_EDUCATION_TYPE_LIST_FAILURE = 'GET_EDUCATION_TYPE_LIST_FAILURE';

export const UPDATE_EDUCATION_TYPE_REQUEST = 'UPDATE_EDUCATION_TYPE_REQUEST';
export const UPDATE_EDUCATION_TYPE_SUCCESS = 'UPDATE_EDUCATION_TYPE_SUCCESS';
export const UPDATE_EDUCATION_TYPE_FAILURE = 'UPDATE_EDUCATION_TYPE_FAILURE';

export const DELETE_EDUCATION_TYPE_REQUEST = 'DELETE_EDUCATION_TYPE_REQUEST';
export const DELETE_EDUCATION_TYPE_SUCCESS = 'DELETE_EDUCATION_TYPE_SUCCESS';
export const DELETE_EDUCATION_TYPE_FAILURE = 'DELETE_EDUCATION_TYPE_FAILURE';


export const createGroupType = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_GROUP_TYPE' });
            dispatch({ type: CREATE_GROUP_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/group/type/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: CREATE_GROUP_TYPE_SUCCESS, payload: response.data });
            toast('Group Type Created Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_GROUP_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const groupTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_GROUP_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/group/type/list/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_GROUP_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_GROUP_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateGroupType = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_GROUP_TYPE' });
            dispatch({ type: UPDATE_GROUP_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/group/type/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_GROUP_TYPE_SUCCESS, payload: response.data });
            toast('Group Type Updated Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_GROUP_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteGroupType = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_GROUP_TYPE' });
            dispatch({ type: DELETE_GROUP_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/group/type/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_GROUP_TYPE_SUCCESS, payload: response.data });
            toast('Group Type Deleted Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_GROUP_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

// export const createQuestionType = (data) => {
//     return async (dispatch) => {
//         try {
//             dispatch({ type: 'CLEAR_CREATE_QUESTION_TYPE' });
//             dispatch({ type: CREATE_QUESTION_TYPE_REQUEST });
//             const url = `${process.env.REACT_APP_BASE_URL}/admin/create/question/type/v1`;
//             const response = await axios.post(url, data, {
//                 headers: {
//                     Authorization: localStorage.getItem('Authorization')
//                 }
//             });
//             dispatch({ type: CREATE_QUESTION_TYPE_SUCCESS, payload: response.data });
//             toast('Question Type Created Successfully.');
//         } catch (error) {
//             console.error(error);
//             dispatch({ type: CREATE_QUESTION_TYPE_FAILURE, payload: error.message });
//             toast(error.response.data.message);
//         }
//     }
// };

export const questionTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_QUESTION_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/question/type/list/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_QUESTION_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_QUESTION_TYPE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

// export const updateQuestionType = (data, id) => {
//     return async (dispatch) => {
//         try {
//             dispatch({ type: 'CLEAR_CREATE_QUESTION_TYPE' });
//             dispatch({ type: UPDATE_QUESTION_TYPE_REQUEST });
//             const url = `${process.env.REACT_APP_BASE_URL}/admin/update/question/type/${id}/v1`;
//             const response = await axios.put(url, data, {
//                 headers: {
//                     Authorization: localStorage.getItem('Authorization')
//                 }
//             });
//             dispatch({ type: UPDATE_QUESTION_TYPE_SUCCESS, payload: response.data });
//             toast('Question Type Updated Successfully.');
//         } catch (error) {
//             console.error(error);
//             dispatch({ type: UPDATE_QUESTION_TYPE_FAILURE, payload: error.message });
//             toast(error.response.data.message);
//         }
//     }
// };

// export const deleteQuestionType = (id) => {
//     return async (dispatch) => {
//         try {
//             dispatch({ type: 'CLEAR_CREATE_QUESTION_TYPE' });
//             dispatch({ type: DELETE_QUESTION_TYPE_REQUEST });
//             const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/question/type/${id}/v1`;
//             const response = await axios.delete(url, {
//                 headers: {
//                     Authorization: localStorage.getItem('Authorization')
//                 }
//             });
//             dispatch({ type: DELETE_QUESTION_TYPE_SUCCESS, payload: response.data });
//             toast('Question Type Deleted Successfully.');
//         } catch (error) {
//             console.error(error);
//             dispatch({ type: DELETE_QUESTION_TYPE_FAILURE, payload: error.message });
//             toast(error.response.data.message);
//         }
//     }
// };

export const createArgumentType = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_ARGUMENT_TYPE' });
            dispatch({ type: CREATE_ARGUMENT_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/argument/type/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: CREATE_ARGUMENT_TYPE_SUCCESS, payload: response.data });
            toast('Argument Type Created Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_ARGUMENT_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const argumentTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ARGUMENT_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/argument/type/list/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_ARGUMENT_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ARGUMENT_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateArgumentType = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_ARGUMENT_TYPE' });
            dispatch({ type: UPDATE_ARGUMENT_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/argument/type/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_ARGUMENT_TYPE_SUCCESS, payload: response.data });
            toast('Argument Type Updated Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_ARGUMENT_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteArgumentType = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_ARGUMENT_TYPE' });
            dispatch({ type: DELETE_ARGUMENT_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/argument/type/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_ARGUMENT_TYPE_SUCCESS, payload: response.data });
            toast('Argument Type Deleted Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_ARGUMENT_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};


export const createCivilStateType = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CIVIL_STATE_TYPE' });
            dispatch({ type: CREATE_CIVIL_STATE_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/civil/state/type/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: CREATE_CIVIL_STATE_TYPE_SUCCESS, payload: response.data });
            toast('Civil State Type Created Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_CIVIL_STATE_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const civilStateTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/civil/state/type/list/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateCivilStateType = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CIVIL_STATE_TYPE' });
            dispatch({ type: UPDATE_CIVIL_STATE_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/civil/state/type/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_CIVIL_STATE_TYPE_SUCCESS, payload: response.data });
            toast('Civil State Type Updated Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_CIVIL_STATE_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteCivilStateType = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CIVIL_STATE_TYPE' });
            dispatch({ type: DELETE_CIVIL_STATE_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/civil/state/type/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_CIVIL_STATE_TYPE_SUCCESS, payload: response.data });
            toast('Civil State Type Deleted Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_CIVIL_STATE_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};


export const createEducationType = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_EDUCATION_TYPE' });
            dispatch({ type: CREATE_EDUCATION_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/education/type/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: CREATE_EDUCATION_TYPE_SUCCESS, payload: response.data });
            toast('Education Type Created Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_EDUCATION_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const educationTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_EDUCATION_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/education/type/list/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_EDUCATION_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_EDUCATION_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateEducationType = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_EDUCATION_TYPE' });
            dispatch({ type: UPDATE_EDUCATION_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/education/type/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_EDUCATION_TYPE_SUCCESS, payload: response.data });
            toast('Education Type Updated Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_EDUCATION_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteEducationType = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_EDUCATION_TYPE' });
            dispatch({ type: DELETE_EDUCATION_TYPE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/education/type/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_EDUCATION_TYPE_SUCCESS, payload: response.data });
            toast('Education Type Deleted Successfully.');
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_EDUCATION_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};