import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('Authorization');

export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';

export const GET_COST_REQUEST = 'GET_COST_REQUEST';
export const GET_COST_SUCCESS = 'GET_COST_SUCCESS';
export const GET_COST_FAILURE = 'GET_COST_FAILURE';

export const UPDATE_COST_REQUEST = 'UPDATE_COST_REQUEST';
export const UPDATE_COST_SUCCESS = 'UPDATE_COST_SUCCESS';
export const UPDATE_COST_FAILURE = 'UPDATE_COST_FAILURE';

export const DELETE_USER_PERMANENTLY_REQUEST = 'DELETE_USER_PERMANENTLY_REQUEST';
export const DELETE_USER_PERMANENTLY_SUCCESS = 'DELETE_USER_PERMANENTLY_SUCCESS';
export const DELETE_USER_PERMANENTLY_FAILURE = 'DELETE_USER_PERMANENTLY_FAILURE';

export const getPaymentMethod = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PAYMENT_METHOD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/get/payment/method/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_PAYMENT_METHOD_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PAYMENT_METHOD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updatePaymentMethod = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_PAYMENT_METHOD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/update/payment/method/key/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_PAYMENT_METHOD_SUCCESS, payload: res.data });
            toast("Payment Method updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PAYMENT_METHOD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getCost = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/get/cost/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: GET_COST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateCost = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_COST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/update/cost/key/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: UPDATE_COST_SUCCESS, payload: res.data });
            toast("Payment Method updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_COST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteUserPermanently = (phone, setPhone, deleteToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_USER_PERMANENTLY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/user/data/v1`;
            const res = await axios.post(url, { phone }, {
                headers: {
                    Authorization: localStorage.getItem('Authorization')
                }
            });
            dispatch({ type: DELETE_USER_PERMANENTLY_SUCCESS, payload: res.data });
            toast("User and user data deleted successfully");
            setPhone(null);
            deleteToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_USER_PERMANENTLY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};