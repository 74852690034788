import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const AddPreliminaryOptionModal = ({ addPreliminaryOptionModal, addPreliminaryOptionToggle, surveyActions, surveyDetails, questionData }) => {

  const [question, setQuestion] = useState({
    surveyId: surveyDetails?._id,
    correctAnswer: "",
    options: [""],
    points: [0]
  });
  const [survey, setSurvey] = useState({
    requiredPoints: null
  });
  const [option, setOption] = useState("");
  const [point, setPoint] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestion({
      surveyId: questionData?.surveyId,
      correctAnswer: questionData?.correctAnswer,
      options: questionData?.options,
      points: questionData?.points,
    });
  }, [questionData, surveyDetails]);

  const handleAddPreliminaryOption = () => {
    if (surveyDetails?.preliminaryQuestionType === "direct elimination") {

      if (option === "") {
        toast("Please Enter Option");
        return;
      }

      dispatch(surveyActions.adminAddSurveyPreliminaryOption(questionData?._id, { question: { ...question, options: [...question.options, option] }, survey }, addPreliminaryOptionToggle, clearState));

    } else if (surveyDetails?.preliminaryQuestionType === "point system") {

      if (option === "") {
        toast("Please Enter Option");
        return;
      }

      if (!point) {
        toast("Please Enter Point");
        return;
      }

      dispatch(surveyActions.adminAddSurveyPreliminaryOption(questionData?._id, { question: { ...question, options: [...question.options, option], points: [...question.points, point] }, survey }, addPreliminaryOptionToggle, clearState));

    }
  };

  const clearState = () => {
    setOption("");
    setPoint(null);
  };

  return (
    <React.Fragment>
      <Modal isOpen={addPreliminaryOptionModal} toggle={addPreliminaryOptionToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Edit Survey Question</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          {
            surveyDetails?.preliminaryQuestionType === "direct elimination" &&
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Option</Label>
                    <Input type="text" value={option} onChange={e => {
                      setOption(e.target.value);
                    }} />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          }
          {
            surveyDetails?.preliminaryQuestionType === "point system" &&
            <React.Fragment>
              <Row>
                <Col md="12">
                  <Row>
                    <Col md="10">
                      <FormGroup>
                        <Label>Option</Label>
                        <Input type="text" value={option} onChange={e => setOption(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label>Point</Label>
                        <Input type="number" value={point} onChange={e => setPoint(Number(e.target.value))} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            addPreliminaryOptionToggle();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleAddPreliminaryOption()
          }}>Add</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default AddPreliminaryOptionModal;